import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import titleMixin from "./mixins/titleMixIn";
import "./assets/site.css";

import { VueReCaptcha } from "vue-recaptcha-v3";
Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY });

import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

Vue.use(require("vue-cookies"));
// set secure, only https works
Vue.$cookies.config("1d");

Vue.mixin(titleMixin);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
