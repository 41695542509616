import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import AdminHome from "../components/admin/AdminHome";
import Dashboard from "../components/Dashboard";
import NotFound from "../components/NotFound";
import AdminManageClinic from "../components/admin/AdminManageClinic";
import AdminManageClinics from "../components/admin/AdminManageClinics";
import AdminManageUser from "../components/admin/AdminManageUser";
import AdminManageUsers from "../components/admin/AdminManageUsers";
import Register from "../views/Register";
import ResetPassword from "../views/ResetPassword";
import ManageProfile from "../components/ManageProfile";
import Patients from "../components/Patients";
import PatientForms from "../components/PatientForms";
import ManagePatientForm from "../components/ManagePatientForm";
import ManagePatient from "../components/ManagePatient";
import CreatePatient from "../components/CreatePatient";
import CreatePatientForm from "../components/CreatePatientForm";
import ContactUs from "../components/ContactUs";
import ApplicationError from "../components/ApplicationError";
import SearchResultsClinic from "../components/SearchResultClinic";
import SearchResultsAdmin from "../components/admin/SearchResultAdmin";
import AdvancedSearch from "../components/admin/AdvancedSearch";
import AdminRegisterClinic from "../components/admin/AdminRegisterClinic";
import AdminVisitsPerClinic from "../components/admin/AdminVisitsPerClinic";

import store from "../store/index";
import cookieWrapper from "../auth/cookieWrapper";
import axios from "axios";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "default",
    component: Login,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/register",
    name: "register",
    component: Register,
  },
  {
    path: "/password-reset/:token?",
    name: "password-reset",
    component: ResetPassword,
    props: true,
  },
  {
    path: "/home",
    name: "home",
    component: Home,
    props: true,
    children: [
      {
        name: "search-result-clinic",
        path: "/home/search-result-clinic/:q",
        component: SearchResultsClinic,
        props: true,
      },
      {
        name: "search-result-admin",
        path: "/home/search-result-admin/:q",
        component: SearchResultsAdmin,
        props: true,
      },
      {
        name: "advanced-search",
        path: "/home/advanced-search",
        component: AdvancedSearch,
      },
      {
        name: "manage-profile",
        path: "/home/manage-profile",
        component: ManageProfile,
      },
      {
        name: "patients",
        path: "/home/patients",
        component: Patients,
      },
      {
        name: "manage-patient",
        path: "/home/manage-patient/:id?",
        component: ManagePatient,
        props: true,
      },
      {
        name: "create-patient",
        path: "/home/create-patient",
        component: CreatePatient,
      },
      {
        name: "patient-forms",
        path: "/home/patient-forms",
        component: PatientForms,
      },
      {
        name: "create-patient-form",
        path: "/home/create-new-form/:patientId",
        component: CreatePatientForm,
        props: true,
      },
      {
        name: "patient-form",
        path: "/home/patient-form/:patientId/:formId?",
        component: ManagePatientForm,
        props: true,
      },
      {
        name: "contact-us",
        path: "/home/contact-us",
        component: ContactUs,
        props: true,
      },
      {
        name: "admin-landing",
        path: "/home/admin-landing",
        component: AdminHome,
      },
      {
        name: "landing",
        path: "/home/landing",
        component: Dashboard,
      },
      {
        name: "admin-manage-clinics",
        path: "/home/admin-manage-clinics",
        component: AdminManageClinics,
      },
      {
        name: "admin-manage-clinic",
        path: "/home/admin-manage-clinic/:id",
        component: AdminManageClinic,
        props: true,
      },
      {
        name: "admin-manage-users",
        path: "/home/admin-manage-users",
        component: AdminManageUsers,
      },
      {
        name: "admin-manage-user",
        path: "/home/admin-manage-user/:id",
        component: AdminManageUser,
        props: {
          default: true,
        },
      },
      {
        name: "admin-patient-forms",
        path: "/home/admin-patient-forms",
        component: AdminVisitsPerClinic,
      },
      {
        name: "admin-register-clinic",
        path: "/home/admin-register-clinic",
        component: AdminRegisterClinic,
      },
      {
        name: "admin-patient-visits",
        path: "/home/admin-patient-visits/:patientId",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "settings" */ "../components/admin/AdminPatientVisits.vue"
          ),
        props: true,
      },
      {
        name: "audit",
        path: "/home/admin-audit",
        component: () =>
          import(
            /* webpackChunkName: "settings" */ "../components/admin/AuditLog.vue"
          ),
      },
      {
        name: "fee_schedule",
        path: "/home/fee-schedule",
        component: () =>
          import(
            /* webpackChunkName: "settings" */ "../components/FeeSchedule.vue"
          ),
      },
    ],
  },
  { name: "error", path: "/application-error", component: ApplicationError },
  { path: "*", component: NotFound },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  //console.log(store.getters.isAuthenticated);
  console.log(to.path);
  if (
    to.name == "login" ||
    to.name == "register" ||
    to.name == "password-reset"
  ) {
    next();
    return;
  }
  //console.log(cookieWrapper.getToken(router.app));
  if (
    !store.getters.isAuthenticated ||
    cookieWrapper.getToken(router.app) == null
  ) {
    //check for cookie, if exist reload Vuex store state
    const token = cookieWrapper.getToken(router.app);
    //console.log(token);

    if (token != null) {
      //load state

      const config = {
        method: "get",
        url: `/api/application-state/load?secret_token=${token}`,
        headers: {
          "Content-Type": "application/json",
        },
      };

      let { data } = await axios(config);
      if (data) {
        store.commit("setLoggedInUser", data.user.name);
        store.commit("setLoggedInUserId", data.user.id);
        store.commit("setLoggedInClinicId", data.clinic.id);
        store.commit("setLoggedInClinic", data.clinic.name);
        store.commit("setIsAdmin", data.user.isAdmin);
        store.commit("setIsStaff", data.user.isStaff);
      }
      //console.log(data);
      next();
    } else {
      //go to login page
      next({ name: "login" });
    }
  } else {
    next();
  }
});

export default router;
