<template>
  <div style="display: contents">
    <v-row>
      <v-col>
        <label style="font-size: 1.2em"
          >Interpretation Type
          <span class="required"><strong>(required)</strong></span></label
        >
        <v-row no-gutters>
          <v-radio-group v-model="interpretationTypeId" row
            ><v-radio
              v-for="(item, index) in interpretation_types"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></v-radio
          ></v-radio-group>
        </v-row>
        <label
          v-if="interpretationTypeId == -1"
          class="alert red darken-2 white--text"
          >Select interpretation type to proceed!</label
        >
      </v-col>
    </v-row>
    <v-row v-if="interpretationTypeId != -1">
      <v-col>
        <label style="font-size: 1.2em"
          >Service Type
          <span class="required"><strong>(required)</strong></span></label
        >
        <v-row no-gutters>
          <v-radio-group v-model="serviceTypeId"
            ><v-radio
              v-for="(item, index) in service_types"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></v-radio
          ></v-radio-group>
        </v-row>
        <label v-if="serviceTypeId == -1" class="alert red darken-2 white--text"
          >Select service type to proceed!</label
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
export default {
  name: "UltrosoundTypeTree",
  data() {
    return {
      interpretationTypeId: -1,
      serviceTypeId: -1,
      interpretation_types: [],
      service_types: [],
    };
  },
  validations: {
    interpretationTypeId: {
      required,
      mustBeSelected: function (value) {
        if (value != -1 && value != "") {
          return true;
        }

        return false;
      },
    },
    serviceTypeId: {
      required,
      mustBeSelected: function (value) {
        if (value != -1 && value != "") {
          return true;
        }

        return false;
      },
    },
  },
  props: {
    ultroTypeId: Number,
    lookups: Object,
  },
  mounted() {
    const self = this;
    //const token = cookieWrapper.getToken(self);
    console.log(self.ultroTypeId);

    if (self.ultroTypeId != -1) {
      const ultro_type = self.lookups.ultro_types.filter((item) => {
        return item.id == self.ultroTypeId;
      })[0];
      console.log(ultro_type);

      self.interpretation_types = self.lookups.interpretation_types.filter(
        (item) => {
          //console.log(item);
          return ultro_type.interp_types.includes(item.id);
        }
      );

      if (self.interpretation_types.length == 1) {
        self.interpretationTypeId = self.interpretation_types[0].id;
      }
    }

    this.$emit("childIsReady");
  },

  watch: {
    ultroTypeId() {
      const self = this;

      const ultro_type = self.lookups.ultro_types.filter((item) => {
        return item.id == self.ultroTypeId;
      })[0];
      console.log(ultro_type);

      self.interpretation_types = self.lookups.interpretation_types.filter(
        (item) => {
          //console.log(item);
          return ultro_type.interp_types.includes(item.id);
        }
      );

      if (self.interpretation_types.length == 1) {
        self.interpretationTypeId = self.interpretation_types[0].id;
      } else {
        self.interpretationTypeId = -1;
      }

      self.serviceTypeId = -1;
    },

    interpretationTypeId(newVal) {
      if (newVal == -1) return;

      const self = this;

      const interpretation_type = self.lookups.interpretation_types.filter(
        (item) => {
          return item.id == newVal;
        }
      )[0];

      self.service_types = self.lookups.service_types.filter((item) => {
        //console.log(item);
        return interpretation_type.services.includes(item.id);
      });

      self.serviceTypeId = -1;
    },
  },
};
</script>
