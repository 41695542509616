<template>
  <v-container>
    <v-progress-linear
      class="mt-2"
      :active="loading"
      :indeterminate="loading"
      absolute
      top
      color="indigo accent-4"
    ></v-progress-linear>
    <v-row align="center" justify="center">
      <h1 class="mb-3">{{ model.clinic_name }}</h1>
    </v-row>
    <v-row align="center" justify="center">
      <v-col md="7" sm="5" col="12">
        <v-sheet class="mb-6">
          <v-card>
            <v-card-title>Your clinic's Information</v-card-title>
            <v-card-text>
              <div>Email: {{ $store.state.loggedInUser }}</div>
              <div>Phone: {{ model.clinic_phone }}</div>
              <div>Head Vet: {{ model.head_vet }}</div>
              <div>Total patients: {{ model.total_patients }}</div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text v-on:click="onEdit"
                >Edit Profile</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-sheet>
        <v-sheet class="mb-6">
          <v-card>
            <v-card-title>Latest 5 patient registrations</v-card-title>
            <v-card-text>
              <v-list-item-group color="primary">
                <v-list-item
                  :href="`/home/manage-patient/${item.id}`"
                  v-for="(item, i) in model.latest_patients"
                  :key="i"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-calendar-minus</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-action
                      >{{ item.name }} ({{ item.date }})</v-list-item-action
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-card-text>
          </v-card>
        </v-sheet>
        <v-sheet class="mb-6">
          <v-card>
            <v-card-title>Latest 5 submitted forms</v-card-title>
            <v-card-text>
              <v-list-item-group color="primary">
                <v-list-item
                  :href="`/home/patient-form/${item.patientId}/${item.id}`"
                  v-for="(item, i) in model.latest_forms"
                  :key="i"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-calendar-minus</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-action
                      >For {{ item.patient }} on ({{
                        item.date
                      }})</v-list-item-action
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-card-text>
          </v-card>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import cookieWrapper from "../auth/cookieWrapper";

export default {
  name: "Dashboard",
  title: "HU Portal - Dashboard",
  data() {
    return {
      model: {},
      loading: true,
      selectedItem: 1,
    };
  },
  async mounted() {
    let self = this;

    try {
      const token = cookieWrapper.getToken(self);

      let config = {
        method: "get",
        url: `/api/dashboard/${self.$store.state.loggedInUser}?secret_token=${token}`,
        headers: {
          "Content-Type": "application/json",
        },
      };

      let result = await axios(config);
      console.log(result.data);
      self.model = result.data;
    } catch (error) {
      console.log(error);
    } finally {
      self.loading = false;
    }
  },

  methods: {
    onEdit() {
      this.$router.push("/home/manage-profile");
    },
  },
};
</script>
