<template>
  <v-app id="inspire">
    <v-navigation-drawer
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      app
    >
      <v-list dense>
        <template v-for="item in menu_items">
          <v-row v-if="item.heading" :key="item.heading" align="center">
            <v-col cols="6">
              <v-subheader v-if="item.heading">
                {{ item.heading }}
              </v-subheader>
            </v-col>
            <v-col cols="6" class="text-center">
              <a href="#!" class="body-2 black--text">EDIT</a>
            </v-col>
          </v-row>
          <v-list-group
            v-else-if="item.children"
            :key="item.text"
            v-model="item.model"
            :prepend-icon="item.model ? item.icon : item['icon-alt']"
            append-icon=""
            :to="item.link"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="(child, i) in item.children"
              :key="i"
              :to="child.link"
              link
            >
              <v-list-item-action v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ child.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item v-else :key="item.text" :to="item.link" link>
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="blue darken-3"
      dark
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
        <span class="hidden-sm-and-down">Hound Ultrasound Portal</span>
      </v-toolbar-title>
      <!--<v-text-field
        flat
        solo-inverted
        hide-details
        prepend-inner-icon="mdi-magnify"
        label="Enter a name or date and hit enter"
        class="hidden-sm-and-down"
        @keyup.enter="onKeyUp"
      ></v-text-field>-->
      <v-spacer></v-spacer>
      <div
        :class="{
          'yellow--text': $store.state.isUserStaff,
          'orange--text': !$store.state.isUserStaff && $store.state.isUserAdmin,
        }"
        style="margin-right: 20px"
      >
        Welcome {{ loggedInUser }}
      </div>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on:click="logout" icon v-on="on">
            <v-icon>mdi-logout</v-icon>
          </v-btn>
        </template>
        <span>Logout</span>
      </v-tooltip>

      <v-btn icon>
        <v-icon>mdi-bell</v-icon>
      </v-btn>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn :href="main_site_uri" target="_blank" icon large v-on="on">
            <v-avatar size="32px" item>
              <v-img src="@/assets/hound.jpg" alt="Vuetify"></v-img
            ></v-avatar>
          </v-btn>
        </template>
        <span>Go to houndultrasound.com</span>
      </v-tooltip>
    </v-app-bar>
    <v-main>
      <v-container fluid>
        <v-row align="center" justify="center">
          <router-view></router-view>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import cookieWrapper from "../auth/cookieWrapper";

export default {
  name: "Home",
  title: "HU Portal - Home",
  props: {
    source: String,
  },
  data: () => ({
    main_site_uri: process.env.VUE_APP_MAIN_SITE_URL,

    dialog: false,
    drawer: null,
  }),
  computed: {
    loggedInUser: {
      get() {
        if (this.$store.state.isUserAdmin) {
          return this.$store.state.loggedInUser;
        } else {
          return this.$store.state.loggedInClinic;
        }
      },
    },
    menu_items() {
      if (this.$store.state.isUserAdmin) {
        return [
          {
            icon: "mdi-contacts",
            text: "Overview",
            link: "/home/admin-landing",
          },
          {
            icon: "mdi-chevron-up",
            "icon-alt": "mdi-chevron-down",
            text: "Manage",
            model: true,
            children: [
              {
                icon: "mdi-magnify",
                text: "Advanced Search",
                link: "/home/advanced-search",
              },
              {
                icon: "mdi-home-outline",
                text: "Clinics",
                link: "/home/admin-manage-clinics",
              },
              {
                icon: "mdi-clipboard",
                text: "Visits per clinic",
                link: "/home/admin-patient-forms",
              },
            ],
          },

          /*{ icon: "mdi-cog", text: "Settings", link: "/home/admin-settings" },*/
          {
            icon: "mdi-help-circle",
            text: "Audit log",
            link: "/home/admin-audit",
          },
        ];
      } else {
        return [
          /*{
            icon: "mdi-desktop-mac-dashboard",
            text: "Dashboard",
            link: "/home/landing",
          },*/
          {
            icon: "mdi-chevron-up",
            "icon-alt": "mdi-chevron-down",
            text: "Manage",
            model: true,
            children: [
              {
                icon: "mdi-account-group",
                text: "Patients",
                link: "/home/patients",
              },
              /* {
                icon: "mdi-folder-information",
                text: "Patient Visits",
                link: "/home/patient-forms",
              },
             */
            ],
          },

          {
            icon: "mdi-account",
            text: "Clinic profile",
            link: "/home/manage-profile",
          },
          { icon: "mdi-cog", text: "Contact Us", link: "/home/contact-us" },
        ];
      }
    },
  },
  methods: {
    logout() {
      //clear cookie
      cookieWrapper.removeToken(this);

      //clear Vuex
      this.$store.commit("resetState");

      //navigate to Login view
      this.$router.push({
        name: "login",
      });
    },

    onKeyUp(e) {
      console.log(this.$store.state.isUserAdmin);
      if (this.$store.state.isUserAdmin) {
        this.$router.push(`/home/search-result-admin/${e.target.value}`);
      } else {
        this.$router.push(`/home/search-result-clinic/${e.target.value}`);
      }
    },
  },
};
</script>
