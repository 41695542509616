<template>
  <div>
    <v-row no-gutters v-for="upload in uploads" :key="upload.id">
      <v-col cols="10">
        <v-file-input
          v-bind:id="upload.id"
          v-bind:name="'upload' + upload.id"
          multiple
          chips
          :placeholder="file_upload_placeholder"
          :accept="allowedExtensions"
          label=""
          @change="updateFiles(upload, $event)"
          :disabled="isDisabled(upload)"
        ></v-file-input>
      </v-col>
      <v-col cols="2" align-self="center" align="center">
        <v-btn small color="primary" @click="removeUpload(upload.id)"
          >Remove</v-btn
        >
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-btn
          small
          color="primary"
          v-if="uploads.length < 3"
          @click="addUpload"
          >Attach File(s)</v-btn
        >

        <span class="counter">
          Selected
          {{ this.uploads.reduce((total, obj) => total + obj.files.length, 0) }}
          file(s). Total size {{ totalUploadSize }} Mb.
        </span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      uploads: [],
      nextId: 1,
      file_upload_placeholder: `attach relevant files (Ctrl + click to select up to ${process.env.VUE_APP_ALLOWED_MAX_FILES} files from the same folder)`,
    };
  },
  computed: {
    allowedExtensions() {
      return process.env.VUE_APP_ALLOWED_UPLOAD_TYPES;
    },
    totalUploadSize() {
      if (this.uploads.length == 0) {
        return 0;
      } else {
        let fileSize = 0;
        this.uploads.map((t) => {
          fileSize += t.files.reduce((total, obj) => total + obj.size, 0);
        });

        return (fileSize / 1000 / 1000).toFixed(2);
      }
    },
  },

  methods: {
    isDisabled(val) {
      console.log(val);
      return this.uploads.filter((value) => {
        return value.id == val.id;
      })[0].disabled;
    },
    addUpload() {
      this.uploads.push({ id: `${this.nextId++}`, files: [], disabled: false });
    },
    removeUpload(id) {
      this.uploads = this.uploads.filter((upload) => upload.id !== id);

      this.$emit(
        "selected-files",
        this.uploads.map((f) => {
          return f.files;
        })
      );
    },
    updateFiles(upload, files) {
      upload.files = files;
      upload.disabled = true;

      this.$emit(
        "selected-files",
        this.uploads.map((f) => {
          return f.files;
        })
      );
    },
  },
};
</script>

<style scoped>
.counter {
  padding: 10px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bolder;
}
</style>
