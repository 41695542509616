<template>
  <v-app>
    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="blue darken-3"
      dark
    >
      <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
        <span class="hidden-sm-and-down">Hound Ultrasound Portal</span>
      </v-toolbar-title>
    </v-app-bar>
    <v-main>
      <v-container>
        <div style="margin-top: 50px; font-size: 1.5em">
          Oops.. Resource you are looking for is not found
        </div>
        <div><a v-on:click="$router.go(-1)">Back home</a></div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "NotFound",
  data() {
    return {
      original_rout: "",
    };
  },
};
</script>
