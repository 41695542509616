<template>
  <v-container>
    <v-progress-linear
      class="mt-2"
      :active="loading"
      :indeterminate="loading"
      absolute
      top
      color="indigo accent-4"
    ></v-progress-linear>
    <v-row align="center" justify="center">
      <h1>
        Visit for <a :href="`/home/manage-patient/${patientId}`">{{ name }}</a>
      </h1>
    </v-row>
    <v-row align="center" justify="center">
      <v-col md="9" sm="5" col="6">
        <v-card class="mb-3">
          <v-row>
            <v-col md="2" sm="2" col="2">
              <v-btn
                style="margin: 5px"
                color="primary"
                small
                text
                @click="onGeneratePdf"
              >
                Save As PDF
              </v-btn></v-col
            >

            <v-col md="3" sm="3" col="3">
              <v-btn
                style="margin: 5px"
                color="primary"
                small
                text
                @click="onCopyToClioboard"
              >
                Copy To Clipboard
              </v-btn></v-col
            >

            <v-col md="7" sm="7" col="7" align="right">
              <v-btn
                style="margin: 5px"
                color="red"
                small
                text
                @click="onDeleteVisit"
                v-show="$store.state.isUserAdmin"
              >
                Delete visit
              </v-btn></v-col
            >
          </v-row>

          <v-card-text>
            <div class="mb-2" v-if="model.form">
              This form was submitted on
              <span
                ><strong>{{
                  date_formatted(model.form.dateCreated)
                }}</strong></span
              >
            </div>
            <div>Age: {{ age }}</div>
            <div>Gender: {{ gender }}</div>
            <div>Species: {{ species }}</div>
            <div>Breed: {{ breed }}</div>
            <v-form class="mt-3">
              <v-row>
                <v-col>
                  <label style="font-size: 1.2em"
                    >Weight
                    <span class="required"
                      ><strong>(required)</strong></span
                    ></label
                  >
                  <v-row no-gutters>
                    <v-col col="3" md="3">
                      <v-radio-group
                        v-model="weightSelection"
                        row
                        :disabled="isDisabled"
                        :readonly="isReadOnly"
                      >
                        <v-radio label="Kg" value="1"></v-radio>
                        <v-radio label="Lb" value="2"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col col="3" md="3">
                      <v-text-field
                        aria-label="enter weight in killograms or pounds"
                        :disabled="isDisabled"
                        :readonly="isReadOnly"
                        type="number"
                        min="1"
                        max="300"
                        v-model="weight"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-select
                    v-if="model && model.lookups"
                    v-model="ultro_type"
                    item-text="name"
                    item-value="id"
                    :items="model.lookups.ultro_types"
                    label="Type of Ultrasound"
                    @change="onUltroTypeChange"
                    :disabled="isDisabled"
                    :readonly="isReadOnly"
                  >
                    <template #label>
                      Type of Ultrasound
                      <span class="required"><strong>(required)</strong></span>
                    </template>
                  </v-select>
                  <v-text-field
                    v-model="ultro_other_text"
                    v-if="showUltroOther"
                    hint="Provide other information"
                    label="Other information"
                    :disabled="isDisabled"
                    :readonly="isReadOnly"
                  >
                    <template #label>
                      Other information
                      <span class="required"><strong>(required)</strong></span>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>

              <UltraTypeTreeReadOnly
                v-if="interpretationTypeId != 0"
                :lookups="model == null ? null : model.lookups"
                :ultroTypeId="ultro_type"
                :interpretationTypeId="interpretationTypeId"
                :serviceTypeId="serviceTypeId"
                :disabled="isDisabled"
                :readonly="isReadOnly"
              ></UltraTypeTreeReadOnly>

              <UltraTypeTreeBicavityReadOnly
                v-if="ultro_type == 5 && bicavityModel != undefined"
                :lookups="model == null ? null : model.lookups"
                :bicavityModel="bicavityModel"
                :disabled="isDisabled"
                :readonly="isReadOnly"
              ></UltraTypeTreeBicavityReadOnly>

              <v-textarea
                auto-grow
                label="History"
                v-model="reason"
                :disabled="isDisabled"
                :readonly="isReadOnly"
              >
                <template #label
                  >History
                  <span class="required"
                    ><strong>(required)</strong></span
                  ></template
                >
              </v-textarea>
              <v-textarea
                auto-grow
                label="Medications"
                v-model="medication"
                :disabled="isDisabled"
                :readonly="isReadOnly"
                ><template #label
                  >Medications
                  <span class="required"
                    ><strong>(required)</strong></span
                  ></template
                ></v-textarea
              >
              <v-row>
                <v-col>
                  <label style="font-size: 1.2em"
                    >Are you adding x-rays for interpretation (extra charge)
                    <span class="required"
                      ><strong>(required)</strong></span
                    ></label
                  >
                  <v-row no-gutters>
                    <v-col col="3" md="3">
                      <v-radio-group
                        v-model="xray"
                        :disabled="isDisabled"
                        :readonly="isReadOnly"
                        row
                      >
                        <v-radio label="Yes" value="1"></v-radio>
                        <v-radio label="No" value="2"></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <label style="font-size: 1.2em"
                    >Are you submitting Lab results or any other documents
                    <span class="required"
                      ><strong>(required)</strong></span
                    ></label
                  >
                  <v-row no-gutters>
                    <v-col col="3" md="3">
                      <v-radio-group
                        v-model="labResults"
                        :disabled="isDisabled"
                        :readonly="isReadOnly"
                        row
                      >
                        <v-radio label="Yes" value="1"></v-radio>
                        <v-radio label="No" value="2"></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row v-if="model.form && model.form.addendums.length > 0">
                <v-col>
                  <div style="font-size: 1.2em">Addendums</div>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Text</th>
                          <th class="text-left">Added date</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item, index) in model.form.addendums"
                          :key="index"
                        >
                          <td>{{ item.text }}</td>
                          <td width="23%">
                            {{ date_formatted(item.dateCreated) }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>

              <v-row v-if="model.form && model.form.files.length > 0">
                <v-col>
                  <div style="font-size: 1.2em">Uploaded documents</div>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Document</th>
                          <th class="text-left">Uploaded on</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item, index) in model.form.files"
                          :key="index"
                        >
                          <td>
                            <a href="#" @click="onDocumentDownload(item._id)">{{
                              item.name
                            }}</a>
                          </td>
                          <td>{{ date_formatted(item.dateUploaded) }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>

              <v-card-actions justify="end" v-show="!$store.state.isUserAdmin">
                <v-btn
                  small
                  color="primary"
                  @click="$router.push(`/home/patients`)"
                >
                  Back
                </v-btn>

                <v-spacer></v-spacer>
                <v-spacer>
                  <v-btn
                    v-if="isDisabled"
                    small
                    color="primary"
                    @click="onAddendum"
                  >
                    Add addendum
                  </v-btn></v-spacer
                >

                <v-spacer></v-spacer>
                <v-spacer>
                  <v-btn
                    v-if="isDisabled"
                    small
                    color="primary"
                    @click="onUpload"
                  >
                    Attach a document
                  </v-btn></v-spacer
                >
                <v-btn
                  small
                  color="primary"
                  @click="onSubmit"
                  :disabled="isReadOnly ? isReadOnly : $v.$invalid"
                >
                  Proceed
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog_review" persistent max-width="330">
      <v-card>
        <v-card-title>Review visit information</v-card-title>
        <v-card-text>
          <div>Weight: {{ weight }}</div>
          <div>Reason: {{ reason }}</div>
          <div>Medications: {{ medication }}</div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="dialog_review = false">
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="onProceed"> Submit </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_addendum" persistent max-width="420">
      <v-card class="pt-3">
        <v-card-text>
          <v-textarea
            auto-grow
            hint="enter addendum text"
            label="Addendum"
            v-model="addendum"
            ><template #label
              >Addendum
              <span class="required"
                ><strong>(required)</strong></span
              ></template
            ></v-textarea
          >
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="dialog_addendum = false">
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="onAddAddendum"
            :disabled="!allowAddendum"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_upload" persistent max-width="420">
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-card class="pt-3">
        <v-card-text>
          <v-file-input
            multiple
            chips
            :placeholder="file_upload_placeholder"
            v-model="files"
            show-size
            :accept="allowedExtensions"
            label=""
            @change="onFileSelected"
          ></v-file-input>

          <v-alert color="orange" dense v-show="error_invalidFileSize"
            >Upload file limit is 50 Mb. Please select a file with smaller
            size.</v-alert
          >
          <v-alert color="orange" dense v-show="error_tooManyFiles">{{
            max_num_files
          }}</v-alert>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            text
            @click="
              dialog_upload = false;
              file = null;
              invalidFileSize = false;
            "
          >
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="onFileUpload"
            :disabled="!allowUpload"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import cookieWrapper from "../auth/cookieWrapper";
import dateTimeFormatter from "../util/dateTimeFormatter";
import { saveAs } from "file-saver";

import UltraTypeTreeReadOnly from "./sub/UltraTypeTreeReadOnly";
import UltraTypeTreeBicavityReadOnly from "./sub/UltraTypeTreeBicavityReadOnly";

export default {
  name: "ManagePatientForm",
  title: "HU Portal - Patient Form",
  props: {
    patientId: String,
    formId: String,
  },
  data() {
    return {
      loading: true,
      overlay: false,
      dialog_addendum: false,
      dialog_upload: false,
      dialog_review: false,
      name: "",
      age: "",
      gender: "",
      species: "",
      breed: "",
      weight: "",
      weightSelection: -1,
      ultro_type: -1,
      interpretationTypeId: -1,
      serviceTypeId: -1,
      reason: "",
      medication: "",
      xray: "",
      labResults: "",
      isReadOnly: false,
      isDisabled: false,
      model: {},
      proceed: false,
      addendum: "",
      bicavityModel: null,

      files: undefined,
      error_invalidFileSize: false,
      error_tooManyFiles: false,
      ultro_other_text: "",
      max_num_files: `Maximum number of files to upload is ${process.env.VUE_APP_ALLOWED_MAX_FILES}`,
      file_upload_placeholder: `attach lab results and other relevant files (Ctrl + click for up to ${process.env.VUE_APP_ALLOWED_MAX_FILES} file selection from same folder)`,
    };
  },
  components: { UltraTypeTreeReadOnly, UltraTypeTreeBicavityReadOnly },

  validations: {
    weight: {
      required,
      maxLenght: maxLength(3),
      mustBeSelected: function (value) {
        if (value != "") {
          return true;
        }

        return false;
      },
    },
    weightSelection: {
      required,
      mustBeSelected: function (value) {
        if (value != -1) {
          return true;
        }

        return false;
      },
    },
    reason: {
      required,
      minLength: minLength(10),
    },
    medication: {
      required,
      minLength: minLength(10),
    },
  },
  async mounted() {
    const self = this;

    try {
      const token = cookieWrapper.getToken(self);

      let config = null;

      //existing form read-only
      if (self.$store.state.isUserAdmin) {
        self.isReadOnly = true;
        self.isDisabled = false;
      } else {
        self.isDisabled = true;
        self.isReadOnly = false;
      }

      config = {
        method: "get",
        url: `/api/patient-form/model/${self.formId}?secret_token=${token}`,
        headers: {
          "Content-Type": "application/json",
        },
      };

      const result = await axios(config);
      self.model = result.data;
      //console.log(result.data);

      //existing form
      //Important: weight is attribute of form AND patient
      //When new form is created, patient weight is updated.
      //BUT, we want to see original form weight
      self.weight =
        self.model.form.weight.weightKg != 0
          ? self.model.form.weight.weightKg
          : self.model.form.weight.weightLb;

      self.weightSelection = self.model.form.weight.weightKg == 0 ? "2" : "1";
      self.reason = self.model.form.reason;
      self.medication = self.model.form.medications;
      self.xray = self.model.form.xRayForInterpretation ? "1" : "2";
      self.labResults = self.model.form.labResults ? "1" : "2";
      self.ultro_type = self.model.form.ultro_type.id;
      self.interpretationTypeId =
        self.model.form.interpretation_type != undefined
          ? self.model.form.interpretation_type.id
          : 0;
      self.serviceTypeId =
        self.model.form.service_type != undefined
          ? self.model.form.service_type.id
          : 0;
      self.ultro_other_text = self.model.form.ultro_other_text;

      self.bicavityModel = self.model.form.bicavity;

      //patient info
      self.name = `${self.model.patient.lastName}, ${self.model.patient.name}`;
      self.species = self.model.patient.species.name;
      self.age = self.model.patient.age_formatted;
      self.gender = self.model.patient.gender.name;
      self.breed = self.model.patient.breed;
    } catch (err) {
      self.$router.push("/application-error");
    } finally {
      this.loading = false;
    }
  },
  methods: {
    onAddendum() {
      this.dialog_addendum = true;
    },
    async onSubmit() {
      const self = this;
      self.dialog_review = true;
    },

    async onProceed() {
      const self = this;
      const token = cookieWrapper.getToken(self);

      const payload = {
        clinicId: self.$store.state.loggedInClinicId,
        patientId: self.patientId,
        medications: self.medication,
        reason: self.reason,
        weight: {
          weightKg: self.weightSelection == 1 ? self.weight : 0,
          weightLb: self.weightSelection == 2 ? self.weight : 0,
        },
        loggedInUser: self.$store.state.loggedInUser,
      };

      const config = {
        method: "put",
        url: `/api/patient-form/create?secret_token=${token}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: payload,
      };

      const result = await axios(config);
      console.log(result);
      self.$router.push("/home/patients");
    },

    async onAddAddendum() {
      const self = this;
      const token = cookieWrapper.getToken(self);

      const payload = {
        formId: self.formId,
        medications: self.medication,
        addendum: self.addendum,
        loggedInUser: self.$store.state.loggedInUser,
      };

      const config = {
        method: "put",
        url: `/api/patient-form/create-addendum?secret_token=${token}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: payload,
      };

      const result = await axios(config);
      console.log(result);

      //self.$router.push("/home/patients");
      //force reload
      self.$router.go(0);
    },

    onUpload() {
      this.dialog_upload = true;
    },
    async onFileUpload() {
      const self = this;
      const token = cookieWrapper.getToken(self);

      self.overlay = true;

      let formData = new FormData();
      let counter = 1;
      self.files.forEach((element) => {
        formData.append(`file${counter}`, element);
        formData.append(
          `file_name${counter}`,
          encodeURIComponent(element.name)
        );
        counter++;
      });

      formData.append("formId", self.formId);
      formData.append("loggedInUser", self.$store.state.loggedInUser);
      formData.append("file_count", self.files.length);

      const config = {
        method: "post",
        url: `/api/patient-form/upload-files?secret_token=${token}`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const result = await axios(config);
      console.log(result);

      //self.$router.push("/home/patients");
      self.$router.go(0);
    },

    date_formatted(d) {
      return dateTimeFormatter.formatWithMask(d, "YYYY-MM-DD hh:mm a");
    },

    onDocumentDownload(docId) {
      const self = this;
      const token = cookieWrapper.getToken(self);

      axios({
        url: `/api/patient-form/download/${self.formId}/${docId}?secret_token=${token}`,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));

        //console.log(response.headers["customx"]);

        var fileLink = document.createElement("a");

        fileLink.href = fileURL;

        fileLink.setAttribute("download", `${response.headers["customx"]}`);

        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },

    onFileSelected() {
      this.error_tooManyFiles = false;
      this.error_invalidFileSize = false;

      const count = this.files.length;
      if (count > Number(process.env.VUE_APP_ALLOWED_MAX_FILES)) {
        this.error_tooManyFiles = true;
      }

      //let fileSize = this.files.reduce((t) => t++);
      let fileSize = 0;
      this.files.map((t) => {
        fileSize += t.size;
      });

      if (fileSize > Number(process.env.VUE_APP_ALLOWED_MAX_UPLOAD_SIZE)) {
        this.error_invalidFileSize = true;
      }
    },

    onUltroTypeChange() {
      if (this.ultro_type != "1") {
        this.ultro_other_text = "";
      }
    },

    async onCopyToClioboard() {
      const self = this;

      try {
        let text = `
Name: ${self.name}
Age: ${self.age}
Gender: ${self.gender}
Species: ${self.species}
Breed: ${self.breed}
Weight: ${self.weight} ${self.weightSelection == 1 ? "Kg" : "Lb"}
Type of ultrasound: ${
          self.model.lookups.ultro_types.filter((item) => {
            return item.id == self.ultro_type;
          })[0].name
        }`;

        if (
          self.model.form.bicavity == undefined &&
          self.interpretationTypeId != 0
        ) {
          text += `\r\nType of interpretation: ${
            self.model.lookups.interpretation_types.filter((item) => {
              return item.id == self.interpretationTypeId;
            })[0].name
          }`;

          text += `\r\nType of service: ${
            self.model.lookups.service_types.filter((item) => {
              return item.id == self.serviceTypeId;
            })[0].name
          }`;
        } else if (self.model.form.bicavity != undefined) {
          if (self.model.form.bicavity.other != undefined) {
            text += "\r\nType of Bicavity Ultrasound: Other\r\n";
            text += `\t${self.model.form.bicavity.other.other_text}`;
          } else {
            const abdominal =
              self.model.form.bicavity.abdominal_cardiac.abdominal;

            text += "\r\nType of Bicavity Ultrasound: Abdominal\r\n";

            text += `\tType of interpretation: ${
              self.model.lookups.interpretation_types.filter((item) => {
                return item.id == abdominal.interpretation_type.id;
              })[0].name
            }`;

            text += `\r\n\tType of service: ${
              self.model.lookups.service_types.filter((item) => {
                return item.id == abdominal.service_type.id;
              })[0].name
            }`;

            const cardiac = self.model.form.bicavity.abdominal_cardiac.cardiac;

            text += "\r\nType of Bicavity Ultrasound: Cardiac\r\n";

            text += `\tType of interpretation: ${
              self.model.lookups.interpretation_types.filter((item) => {
                return item.id == cardiac.interpretation_type.id;
              })[0].name
            }`;

            text += `\r\n\tType of service: ${
              self.model.lookups.service_types.filter((item) => {
                return item.id == cardiac.service_type.id;
              })[0].name
            }`;
          }
        }

        text += `
History: ${self.reason}
Medications: ${self.medication}
Are you adding x-rays for interpretation: ${self.xray == 1 ? "Yes" : "No"}
Are you submitting Lab results or any other documents: ${
          self.labResults == 1 ? "Yes" : "No"
        }`;

        if (self.model.form.addendums.length > 0) {
          text += "\r\nAddendums:\r\n";
          for (let i = 0; i < self.model.form.addendums.length; i++) {
            text += "\t" + self.model.form.addendums[i].text + "\r\n";
          }
        }

        await navigator.clipboard.writeText(text.trim());
        alert("Copied to clipboard!");
      } catch (err) {
        console.log(err);
      }
    },

    async onGeneratePdf() {
      const self = this;
      const token = cookieWrapper.getToken(self);

      self.loading = true;

      const payload = {
        client: {
          name: self.name,
          age: self.age,
          gender: self.gender,
          species: self.species,
          breed: self.breed,
        },
        visit: {
          weight: `${self.weight} ${self.weightSelection == 1 ? "Kg" : "Lb"}`,
          ultro: self.model.lookups.ultro_types.filter((item) => {
            return item.id == self.ultro_type;
          })[0].name,
          history: self.reason,
          meds: self.medication,
          xray: self.xray == 1 ? "Yes" : "No",
          labResults: self.labResults == 1 ? "Yes" : "No",
        },
        templateName: "visit.html",
      };

      const config = {
        method: "post",
        url: `/api/pdf/generate?secret_token=${token}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(payload),
        responseType: "arraybuffer", //this is important when returning a binary file
        responseEncoding: "binary", //this is important when returning a binary file
      };

      const response = await axios(config);
      //convert returned buffer to Blob
      var blob = new Blob([response.data], {
        type: "application/pdf;charset=utf-8",
      });

      self.loading = false;
      //trigger save in the browser
      saveAs.saveAs(blob);
    },

    async onDeleteVisit() {
      const answer = confirm(
        "This will permanantly delete this visit and all documents! Proceed?"
      );

      if (!answer) return;

      const self = this;
      const token = cookieWrapper.getToken(self);

      const payload = {
        id: self.formId,
      };

      const config = {
        method: "delete",
        url: `/api/patient-form/delete?secret_token=${token}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(payload),
      };

      await axios(config);

      self.$router.push("/home/admin-landing");
    },
  },

  computed: {
    allowAddendum() {
      if (this.addendum == "") {
        return false;
      }

      return true;
    },

    allowUpload() {
      if (this.files == undefined || this.files.length == 0) {
        return false;
      }

      if (this.error_invalidFileSize) {
        return false;
      }

      if (this.error_tooManyFiles) {
        return false;
      }

      return true;
    },

    allowedExtensions() {
      return process.env.VUE_APP_ALLOWED_UPLOAD_TYPES;
    },

    showUltroOther() {
      if (this.ultro_type == "1") return true;

      return false;
    },
  },
};
</script>

<style scoped></style>
