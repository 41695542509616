<template>
  <v-container>
    <v-progress-linear
      class="mt-2"
      :active="loading"
      :indeterminate="loading"
      absolute
      top
      color="indigo accent-4"
    ></v-progress-linear>
    <v-row justify="center" align="center">
      <h1 style="margin-bottom: 30px">
        Welcome to Hound Ultrasound Portal - Admin
      </h1>
    </v-row>
    <v-row justify="center" align="center">
      <v-col cols="12" sm="8" md="10">
        <v-card>
          <v-card-title
            ><span class="ml-2 mt-2">50 latest visits</span></v-card-title
          >
          <v-data-table
            v-if="model"
            hide-default-footer
            :itemsPerPage="itemsPerPage"
            :headers="headers"
            :items="model.forms"
            :sort-by="['dateCreated']"
            :sort-desc="[true]"
          >
            <template v-slot:[`item.patient`]="{ item }">
              <a :href="`/home/manage-patient/${item.patientId}`">{{
                item.patient
              }}</a>
            </template>

            <template v-slot:[`item.clinic`]="{ item }">
              <a :href="`/home/admin-manage-clinic/${item.clinicId}`">{{
                item.clinic
              }}</a>
            </template>

            <template v-slot:[`item.dateCreated`]="{ item }">
              {{ formatDateTime(item.dateCreated) }}
            </template>

            <template v-slot:[`item.review`]="{ item }">
              <v-icon
                title="Review patient visit"
                color="blue darken-2"
                @click="
                  $router.push(
                    `/home/patient-form/${item.patientId}/${item.id}`
                  )
                "
              >
                mdi-clipboard-edit
              </v-icon></template
            >
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="10">
        <v-sheet>
          <v-card class="mb-6">
            <v-card-text
              >You currently have
              <v-avatar v-if="model" color="green darken-4" size="45">
                <span class="white--text headline">{{
                  model.approved_clinic_count
                }}</span>
              </v-avatar>
              approved clinic(s)
            </v-card-text>
          </v-card>
          <v-card>
            <v-card-text
              >You currently have
              <v-avatar v-if="model" color="red darken-2" size="45">
                <span class="white--text headline">{{
                  model.not_approved_clinic_count
                }}</span>
              </v-avatar>
              clinic(s) pending approval.</v-card-text
            >
          </v-card>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import cookieWrapper from "../../auth/cookieWrapper";
import dateTimeFormatter from "../../util/dateTimeFormatter";

export default {
  name: "AdminHome",
  title: "HU Portal - Admin Home",

  data() {
    return {
      model: null,
      loading: true,
      itemsPerPage: 50,
      headers: [
        { text: "Patient", value: "patient", width: "30%" },
        { text: "Clinic", value: "clinic", width: "30%" },
        { text: "Date created", value: "dateCreated", dataType: "Date" },
        // { text: "Reason", value: "reason", width: "21%" },
        // { text: "Addendums", value: "addendums", align: "center" },
        // { text: "Documents", value: "documents", align: "center" },
        {
          value: "review",
          filterable: false,
          sortable: false,
          align: "center",
        },
      ],
    };
  },

  async mounted() {
    let self = this;

    try {
      const token = cookieWrapper.getToken(self);

      let config = {
        method: "get",
        url: `/api/landing/admin?secret_token=${token}`,
        headers: {
          "Content-Type": "application/json",
        },
      };

      let result = await axios(config);
      self.model = result.data;
      self.loading = false;

      console.log(result.data);
    } catch (err) {
      console.log(err);
    }
  },

  methods: {
    formatDateTime(value) {
      return dateTimeFormatter.formatWithMask(value, "YYYY-MM-DD hh:mm A");
    },
  },
};
</script>
