var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-progress-linear',{staticClass:"mt-2",attrs:{"active":_vm.loading,"indeterminate":_vm.loading,"absolute":"","top":"","color":"indigo accent-4"}}),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('h1',[_vm._v("Patient Visits Per Clinic")])]),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"8","md":"10"}},[_c('v-card',[_c('v-card-text',[_c('v-autocomplete',{attrs:{"item-text":"name","item-value":"id","items":_vm.clinics,"label":"Clinics"},on:{"change":_vm.onClinicChange},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Select clinic ")]},proxy:true}]),model:{value:(_vm.selectedClinic),callback:function ($$v) {_vm.selectedClinic=$$v},expression:"selectedClinic"}}),(_vm.model)?_c('v-data-table',{attrs:{"itemsPerPage":_vm.itemsPerPage,"headers":_vm.headers,"items":_vm.model.forms,"sort-by":['dateCreated'],"sort-desc":[true]},scopedSlots:_vm._u([{key:"item.patient",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":("/home/manage-patient/" + (item.patientId))}},[_vm._v(_vm._s(item.patient))])]}},{key:"item.clinic",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":("/home/admin-manage-clinic/" + (item.clinicId))}},[_vm._v(_vm._s(item.clinic))])]}},{key:"item.review",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"title":"Review patient visit","color":"blue darken-2"},on:{"click":function($event){return _vm.$router.push(
                    ("/home/patient-form/" + (item.patientId) + "/" + (item.id))
                  )}}},[_vm._v(" mdi-clipboard-edit ")])]}}],null,true)}):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }