<template>
  <v-container>
    <v-progress-linear
      class="mt-2"
      :active="loading"
      :indeterminate="loading"
      absolute
      top
      color="indigo accent-4"
    ></v-progress-linear>
    <v-row align="center" justify="center">
      <h1 class="mb-6">Update clinic information</h1>
    </v-row>
    <v-row align="center" justify="center">
      <v-col md="9" sm="5" col="12">
        <v-card class="elevation-8">
          <v-card-text>
            <v-form>
              <v-text-field v-model="clinicName" label="Name" required>
                <template #label>
                  Name
                  <span class="required"><strong>(required)</strong></span>
                </template>
              </v-text-field>
              <v-text-field
                v-model="clinicEmail"
                label="Email"
                required
                disabled
              >
                <template #label>
                  Email
                  <span class="required"><strong>(required)</strong></span>
                </template>
              </v-text-field>
              <v-text-field
                v-model="clinicEmailBilling"
                label="Billing email"
                required
              ></v-text-field>
              <v-text-field v-model="clinicPhone" label="Phone" required>
                <template #label>
                  Phone
                  <span class="required"><strong>(required)</strong></span>
                </template>
              </v-text-field>
              <v-text-field
                v-model="clinicFax"
                label="Fax"
                required
              ></v-text-field>
              <h2 class="mt-3 mb-2">Address</h2>
              <v-text-field v-model="address1" label="Address Line 1" required>
                <template #label>
                  Address Line 1
                  <span class="required"><strong>(required)</strong></span>
                </template>
              </v-text-field>
              <v-text-field
                v-model="address2"
                label="Address Line 2"
                required
              ></v-text-field>
              <v-text-field v-model="addressCity" label="City" required>
                <template #label>
                  City
                  <span class="required"><strong>(required)</strong></span>
                </template>
              </v-text-field>
              <v-text-field
                v-model="addressProvince"
                label="Province"
                required
                disabled
              ></v-text-field>
              <v-text-field
                v-model="addressPostalCode"
                label="Postal code"
                required
              >
                <template #label>
                  Postal code
                  <span class="required"><strong>(required)</strong></span>
                </template>
              </v-text-field>
              <h2 class="mt-3 mb-2">Director / Head veterinarian</h2>
              <v-text-field
                v-model="clinicDirectorFN"
                label="First name"
                required
              ></v-text-field>
              <v-text-field
                v-model="clinicDirectorLN"
                label="Last name"
                required
              ></v-text-field>
            </v-form>
            <v-card-actions>
              <v-btn small color="warning" v-on:click="onChangeEmail"
                >Change email</v-btn
              >
              <v-spacer></v-spacer>
              <v-btn small color="warning" v-on:click="onChangePwd"
                >Change password</v-btn
              >
              <v-spacer></v-spacer>
              <v-btn
                small
                color="primary"
                v-on:click="update"
                :disabled="$v.$invalid"
                >Update</v-btn
              >
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog_email" persistent max-width="350">
      <v-card>
        <v-card-title class="headline"> Email change </v-card-title>
        <v-card-text
          >You are about to change an email address that is used to login to HU
          Portal. After this change you will need to re-login.
          <v-text-field v-model="newEmail" label="Email" autocomplete="off">
            <template #label>
              Email
              <span class="required"><strong>(required)</strong></span>
            </template>
          </v-text-field>
          <v-text-field
            v-model="newEmailConfirm"
            label="Confirm Email"
            autocomplete="off"
          >
            <template #label>
              Confirm Email
              <span class="required"><strong>(required)</strong></span>
            </template>
          </v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            text
            @click="
              dialog_email = false;
              newEmail = '';
              newEmailConfirm = '';
            "
          >
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!proceedEmailChange"
            color="primary"
            text
            @click="ChangeEmail()"
          >
            Proceed
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_pwd" persistent max-width="350">
      <v-card>
        <v-card-title class="headline"> Password change </v-card-title>
        <v-card-text
          >You are about to change the password that is used to login to HU
          Portal. After this change you will need to re-login.
          <v-text-field
            v-model="currentPwd"
            label="Current password"
            type="password"
            autocomplete="new-password"
          >
            <template #label>
              Current password
              <span class="required"><strong>(required)</strong></span>
            </template>
          </v-text-field>
          <v-text-field
            v-model="newPwd"
            label="New password"
            type="password"
            autocomplete="new-password"
            hint="minimum 6 characters, at least one uppercase and one number"
          >
            <template #label>
              New password
              <span class="required"><strong>(required)</strong></span>
            </template>
          </v-text-field>
          <v-text-field
            v-model="newPwdConfirm"
            label="Confirm new password"
            type="password"
            autocomplete="new-password"
          >
            <template #label>
              Confirm new password
              <span class="required"><strong>(required)</strong></span>
            </template>
          </v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            text
            @click="
              dialog_pwd = false;
              newPwd = '';
              newPwdConfirm = '';
              currentPwd = '';
            "
          >
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!proceedPwdChange"
            color="primary"
            text
            @click="ChangePwd()"
          >
            Proceed
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
import cookieWrapper from "../auth/cookieWrapper";
import { required, email, minLength } from "vuelidate/lib/validators";
//import dateTimeFormatter from "../../util/dateTimeFormatter";

export default {
  name: "ManageProfile",
  title: "HU Portal - Manage Profile",

  data() {
    return {
      loading: true,

      clinicName: "",
      clinicEmail: "",
      clinicEmailBilling: "",
      clinicPhone: "",
      clinicFax: "",
      clinicDirectorFN: "",
      clinicDirectorLN: "",
      addressStreet: "",
      address1: "",
      address2: "",
      addressCity: "",
      addressProvince: "Ontario",
      addressPostalCode: "",
      dialog_email: false,
      dialog_pwd: false,
      newEmail: "",
      newEmailConfirm: "",
      newPwd: "",
      newPwdConfirm: "",
      currentPwd: "",

      clinic: {},
    };
  },

  validations: {
    clinicName: {
      required,
      minLength: minLength(3),
    },
    clinicEmail: {
      email,
      required,
    },
    clinicEmailBilling: {
      email,
    },
    clinicPhone: {
      required,
      minLength: minLength(12),
    },
    address1: {
      required,
    },
    addressCity: {
      required,
    },
    addressProvince: {
      required,
    },
    addressPostalCode: {
      required,
      isValidPostalCode: function (value) {
        //console.log(value);
        var regex = new RegExp(
          /^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]( )?\d[ABCEGHJKLMNPRSTVWXYZ]\d$/i
        );
        //console.log(regex.test(value));
        return regex.test(value);
      },
    },
  },

  async mounted() {
    let self = this;

    try {
      const token = cookieWrapper.getToken(self);

      const config = {
        method: "get",
        url: `/api/clinic/${self.$store.state.loggedInClinicId}?secret_token=${token}`,
        headers: {
          "Content-Type": "application/json",
        },
      };

      const result = await axios(config);
      self.clinic = result.data;

      self.clinicName = self.clinic.name;
      self.clinicEmail = self.clinic.email;
      self.clinicEmailBilling = self.clinic.email_billing;
      self.clinicPhone = self.clinic.phone;
      self.clinicFax = self.clinic.fax;
      self.address1 = self.clinic.address.address1;
      self.address2 = self.clinic.address.address2;
      self.addressCity = self.clinic.address.city;
      self.addressPostalCode = self.clinic.address.postalCode;
      self.clinicDirectorFN = self.clinic.director.firstName;
      self.clinicDirectorLN = self.clinic.director.lastName;

      console.log(result.data);
    } catch (err) {
      console.log(err);
    } finally {
      self.loading = false;
    }
  },
  methods: {
    async update() {
      let self = this;

      try {
        const token = cookieWrapper.getToken(self);

        const payload = {
          id: self.clinic._id,
          name: self.clinicName,
          email: self.clinicEmail,
          email_billing: self.clinicEmailBilling,
          phone: self.clinicPhone,
          fax: self.clinicFax,

          director: {
            firstName: self.clinicDirectorFN,
            lastName: self.clinicDirectorLN,
          },

          address: {
            address1: self.address1,
            address2: self.address2,
            city: self.addressCity,
            province: { id: 7, name: "Ontario" }, //Ontario, for now it's hardcoded
            postalCode: self.addressPostalCode,
          },
        };

        const config = {
          method: "post",
          url: `/api/clinic/update?secret_token=${token}`,
          headers: {
            "Content-Type": "application/json",
          },
          data: payload,
        };

        const result = await axios(config);
        console.log(result);

        self.$router.push("landing");
      } catch (error) {
        console.log(error);
      }
    },

    onChangeEmail() {
      this.dialog_email = true;
    },
    onChangePwd() {
      this.dialog_pwd = true;
    },

    async ChangeEmail() {
      const self = this;

      try {
        const token = cookieWrapper.getToken(self);

        const payload = {
          clinicId: self.clinic._id,
          email: self.newEmail,
          loggedInUser: self.$store.state.loggedInUser,
        };

        const config = {
          method: "post",
          url: `/api/clinic/updateEmail?secret_token=${token}`,
          headers: {
            "Content-Type": "application/json",
          },
          data: payload,
        };

        //update clinic and user
        await axios(config);

        //clear cookie
        cookieWrapper.removeToken(this);

        //clear Vuex state
        this.$store.commit("resetState");

        //navigate to Login view
        this.$router.push({
          name: "login",
        });
      } catch (error) {
        alert("Email change failed!");
        console.log(error);
      }
    },

    async ChangePwd() {
      const self = this;

      try {
        const token = cookieWrapper.getToken(self);

        const payload = {
          clinicId: self.clinic._id,
          current_pwd: self.currentPwd,
          password: self.newPwd,
          loggedInUser: self.$store.state.loggedInUser,
        };

        const config = {
          method: "post",
          url: `/api/user/updatePwd?secret_token=${token}`,
          headers: {
            "Content-Type": "application/json",
          },
          data: payload,
        };

        //update user
        await axios(config);

        //clear cookie
        cookieWrapper.removeToken(this);

        //clear Vuex state
        this.$store.commit("resetState");

        //navigate to Login view
        this.$router.push({
          name: "login",
        });
      } catch (error) {
        alert("Password change failed!");
        console.log(error);
      }
    },
  },

  computed: {
    proceedEmailChange() {
      if (
        this.newEmailConfirm != "" &&
        this.newEmail != "" &&
        this.newEmailConfirm === this.newEmail
      ) {
        const emailRegex = new RegExp(/\S+@\S+\.\S+/);
        if (emailRegex.test(this.newEmail)) {
          return true;
        }

        return false;
      }

      return false;
    },

    proceedPwdChange() {
      if (
        this.newPwd != "" &&
        this.newPwdConfirm != "" &&
        this.newPwdConfirm === this.newPwd
      ) {
        /*var strongRegex = new RegExp(
          "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
        );
        var mediumRegex = new RegExp(
          "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})"
        );*/

        //minimum 6, at least one upper case and one number
        const mediumRegex = new RegExp(
          "^(((?=.*[a-z])(?=.*[A-Z]))((?=.*[a-z])(?=.*[0-9]))((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})"
        );
        if (mediumRegex.test(this.newPwd)) {
          return true;
        }

        return false;
      }

      return false;
    },
  },
};
</script>
