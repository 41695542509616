<template>
  <v-card>
    <v-card-title class="ml-2 mt-2 mt-2">Find patient's form(s)</v-card-title>
    <v-card-text>
      <v-form>
        <v-row>
          <v-col md="4">
            <v-radio-group v-model="searchType" row @change="searchToken = ''">
              <v-radio label="By name or Last name" value="1"></v-radio>
              <!--<v-radio label="By species" value="2"></v-radio>-->
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="4">
            <v-text-field
              v-model="searchToken"
              required
              hint="minimum 3 charachters"
            >
              <template #label>
                Search term
                <span class="required"><strong>(required)</strong></span>
              </template>
            </v-text-field>
          </v-col>
          <v-col>
            <v-btn
              :disabled="!searchButtonEnabled"
              class="mt-4"
              small
              color="primary"
              @click="onSearch"
            >
              Search
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="search_results.length > 0">
          <v-col md="8">
            <v-data-table
              show-expand
              item-key="id"
              :single-expand="single_expand"
              :expanded.sync="expanded"
              :headers="headers"
              :items="search_results"
              loading-text="Loading... Please wait"
              :sort-by="['name']"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>Search results</v-toolbar-title>
                </v-toolbar>
              </template>

              <template v-slot:[`item.forms`]="{ item }">
                {{ item }}
              </template>

              <template v-slot:[`item.add`]="{ item }">
                <v-icon
                  title="Add new form"
                  color="blue darken-2"
                  @click="$router.push(`/home/create-new-form/${item.id}`)"
                >
                  mdi-clipboard
                </v-icon></template
              >

              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" style="padding: 15px">
                  <div>Forms (sorted descending by date submitted)</div>
                  <ul v-if="item.forms.length > 0">
                    <li v-for="i in item.forms" :key="i._id">
                      <a :href="`/home/patient-form/${item.id}/${i._id}`">{{
                        formatDateTime(i.dateCreated)
                      }}</a>
                    </li>
                  </ul>
                  <span v-if="item.forms.length == 0">no forms yet</span>
                </td>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <div id="focus"></div>
        <v-row v-if="no_results">
          <v-col>
            <div>
              <strong
                >no records found for search term
                <em>{{ searchToken }}</em></strong
              >
            </div>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";
import cookieWrapper from "../../auth/cookieWrapper";
import dateTimeFormatter from "../../util/dateTimeFormatter";

export default {
  name: "SearchPatient",
  data() {
    return {
      searchType: "1",
      searchToken: "",
      headers: [
        { text: "Patient", value: "name" },
        { text: "Num of forms", value: "forms.length" },
        { text: "Add", value: "add", filterable: false },
        { text: "", value: "data-table-expand" },
      ],
      search_results: [],
      expanded: [],
      single_expand: true,
      no_results: false,
    };
  },
  methods: {
    async onSearch() {
      const self = this;

      self.no_results = false;

      self.search_results = [];

      try {
        const token = cookieWrapper.getToken(self);

        const payload = {
          searchCriteria: self.searchType == "1" ? "name" : "species",
          searchToken: self.searchToken,
          clinicId: self.$store.state.loggedInClinicId,
        };

        const config = {
          method: "post",
          url: `/api/patient/search?secret_token=${token}`,
          headers: {
            "Content-Type": "application/json",
          },
          data: payload,
        };

        const { data } = await axios(config);
        self.search_results = data;
        if (self.search_results.length == 0) {
          self.no_results = true;
        }

        //scroll to bottom
        self.$vuetify.goTo("#focus");
      } catch (error) {
        console.log(error);
      }
    },

    formatDateTime(value) {
      return dateTimeFormatter.formatWithMask(value, "YYYY-MM-DD hh:mm A");
    },
  },

  computed: {
    searchButtonEnabled() {
      if (this.searchToken && this.searchToken.length >= 3) return true;

      return false;
    },
  },
};
</script>
