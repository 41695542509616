<template>
  <v-container>
    <v-row align="center" justify="center">
      <h1 class="mb-3">Contact HU Portal Admin</h1>
    </v-row>

    <v-row align="center" justify="center">
      <v-col md="9" sm="5" col="12">
        <v-card class="elevation-8">
          <v-card-title>Call us</v-card-title>
          <v-card-text>
            Phone: <a href="tel:+1-647-376-6477">+1 (647) 376-6477</a>
          </v-card-text>
        </v-card>
      </v-col></v-row
    >

    <v-row align="center" justify="center">
      <v-col md="9" sm="5" col="12">
        <v-card class="elevation-8">
          <v-card-title>Or send us an email</v-card-title>
          <v-card-text>
            <v-form>
              <v-text-field
                label="Clinic name"
                disabled
                v-model="name"
              ></v-text-field>

              <v-text-field
                label="Clinic email"
                disabled
                v-model="email"
              ></v-text-field>
              <v-select
                v-model="message_type"
                item-text="name"
                item-value="id"
                :items="message_types"
                label="Reason"
              ></v-select>
              <v-textarea auto-grow v-model="body"
                ><template #label
                  >Message
                  <span class="required"
                    ><strong>(required)</strong></span
                  ></template
                ></v-textarea
              >
            </v-form>
          </v-card-text>
          <v-card-actions justify="end">
            <v-spacer></v-spacer>
            <v-btn small color="primary" @click="onSubmit"> Submit </v-btn>
          </v-card-actions>
        </v-card>
      </v-col></v-row
    >
  </v-container>
</template>

<script>
import axios from "axios";
import cookieWrapper from "../auth/cookieWrapper";

export default {
  name: "ContactUs",
  title: "HU Portal - Contact Us",
  data() {
    return {
      name: this.$store.state.loggedInClinic,
      email: this.$store.state.loggedInUser,
      body: "",
      message_type: 3,
      message_types: [
        { id: 1, name: "Technical issue" },
        { id: 2, name: "Feature request" },
        { id: 3, name: "General" },
      ],
    };
  },
  methods: {
    async onSubmit() {
      const self = this;
      const token = cookieWrapper.getToken(self);

      let payload = {
        clinic_name: self.name,
        clinic_email: self.email,
        message_type: self.TypeNameFromId(self.message_type),
        message_body: self.body,
      };

      let config = {
        method: "post",
        url: `/api/clinic/contact-us?secret_token=${token}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: payload,
      };

      const result = await axios(config);
      console.log(result);
      alert("Thank you for your feedback!");

      this.$router.push({
        name: "patients",
      });
    },

    TypeNameFromId(id) {
      return this.message_types.filter((i) => {
        return i == id;
      })[0];
    },
  },
};
</script>
