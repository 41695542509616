var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-progress-linear',{staticClass:"mt-2",attrs:{"active":_vm.loading,"indeterminate":_vm.loading,"absolute":"","top":"","color":"indigo accent-4"}}),_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('h1',{staticStyle:{"margin-bottom":"30px"}},[_vm._v(" Welcome to Hound Ultrasound Portal - Admin ")])]),_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"8","md":"10"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"ml-2 mt-2"},[_vm._v("50 latest visits")])]),(_vm.model)?_c('v-data-table',{attrs:{"hide-default-footer":"","itemsPerPage":_vm.itemsPerPage,"headers":_vm.headers,"items":_vm.model.forms,"sort-by":['dateCreated'],"sort-desc":[true]},scopedSlots:_vm._u([{key:"item.patient",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":("/home/manage-patient/" + (item.patientId))}},[_vm._v(_vm._s(item.patient))])]}},{key:"item.clinic",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":("/home/admin-manage-clinic/" + (item.clinicId))}},[_vm._v(_vm._s(item.clinic))])]}},{key:"item.dateCreated",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateTime(item.dateCreated))+" ")]}},{key:"item.review",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"title":"Review patient visit","color":"blue darken-2"},on:{"click":function($event){return _vm.$router.push(
                  ("/home/patient-form/" + (item.patientId) + "/" + (item.id))
                )}}},[_vm._v(" mdi-clipboard-edit ")])]}}],null,true)}):_vm._e()],1)],1)],1),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"8","md":"10"}},[_c('v-sheet',[_c('v-card',{staticClass:"mb-6"},[_c('v-card-text',[_vm._v("You currently have "),(_vm.model)?_c('v-avatar',{attrs:{"color":"green darken-4","size":"45"}},[_c('span',{staticClass:"white--text headline"},[_vm._v(_vm._s(_vm.model.approved_clinic_count))])]):_vm._e(),_vm._v(" approved clinic(s) ")],1)],1),_c('v-card',[_c('v-card-text',[_vm._v("You currently have "),(_vm.model)?_c('v-avatar',{attrs:{"color":"red darken-2","size":"45"}},[_c('span',{staticClass:"white--text headline"},[_vm._v(_vm._s(_vm.model.not_approved_clinic_count))])]):_vm._e(),_vm._v(" clinic(s) pending approval.")],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }