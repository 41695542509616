import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loggedInUser: "",
    loggedInUserId: "",
    loggedInClinic: "",
    loggedInClinicId: "",
    isUserAdmin: false,
    isUserStaff: false,
  },
  getters: {
    isAuthenticated: (state) => {
      if (state.loggedInUser != "") return true;
      return false;
    },
  },
  mutations: {
    setLoggedInUser(state, user) {
      state.loggedInUser = user;
    },
    setLoggedInUserId(state, id) {
      state.loggedInUserId = id;
    },

    setLoggedInClinic(state, clinic) {
      state.loggedInClinic = clinic;
    },
    setLoggedInClinicId(state, id) {
      state.loggedInClinicId = id;
    },

    setIsAdmin(state, isAdmin) {
      state.isUserAdmin = isAdmin;
    },

    setIsStaff(state, isStaff) {
      state.isUserStaff = isStaff;
    },

    resetState(state) {
      state.loggedInUser = "";
      state.loggedInUserId = "";
      state.loggedInClinic = "";
      state.loggedInClinicId = "";
      state.isUserAdmin = false;
    },
  },
  actions: {},
  modules: {},
});
