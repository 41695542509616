<template>
  <v-container>
    <v-progress-linear
      class="mt-2"
      :active="loading"
      :indeterminate="loading"
      absolute
      top
      color="indigo accent-4"
    ></v-progress-linear>
    <v-row align="center" justify="center">
      <h1>Patient Visits Per Clinic</h1>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="10">
        <v-card>
          <v-card-text>
            <v-autocomplete
              v-model="selectedClinic"
              item-text="name"
              item-value="id"
              :items="clinics"
              label="Clinics"
              @change="onClinicChange"
              ><template #label> Select clinic </template></v-autocomplete
            >
            <!-- <v-select
              v-model="selectedClinic"
              item-text="name"
              item-value="id"
              :items="clinics"
              label="Clinics"
              @change="onClinicChange"
            >
              <template #label> Select clinic </template>
            </v-select> -->

            <v-data-table
              v-if="model"
              :itemsPerPage="itemsPerPage"
              :headers="headers"
              :items="model.forms"
              :sort-by="['dateCreated']"
              :sort-desc="[true]"
            >
              <template v-slot:[`item.patient`]="{ item }">
                <a :href="`/home/manage-patient/${item.patientId}`">{{
                  item.patient
                }}</a>
              </template>

              <template v-slot:[`item.clinic`]="{ item }">
                <a :href="`/home/admin-manage-clinic/${item.clinicId}`">{{
                  item.clinic
                }}</a>
              </template>

              <template v-slot:[`item.review`]="{ item }">
                <v-icon
                  title="Review patient visit"
                  color="blue darken-2"
                  @click="
                    $router.push(
                      `/home/patient-form/${item.patientId}/${item.id}`
                    )
                  "
                >
                  mdi-clipboard-edit
                </v-icon></template
              >
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import cookieWrapper from "../../auth/cookieWrapper";

export default {
  name: "AdminVisitsPerClinic",
  title: "Visits Per Clinic",
  data() {
    return {
      loading: true,
      clinics: [],
      selectedClinic: "",
      itemsPerPage: 10,
      model: null,
      headers: [
        { text: "Patient", value: "patient", width: "15%" },
        { text: "Date created", value: "dateCreated" },
        { text: "Reason", value: "reason", width: "21%" },
        { text: "Addendums", value: "addendums", align: "center" },
        { text: "Documents", value: "documents", align: "center" },
        {
          value: "review",
          filterable: false,
          sortable: false,
          align: "center",
        },
      ],
    };
  },

  async mounted() {
    const self = this;

    try {
      const token = cookieWrapper.getToken(self);

      let config = {
        method: "get",
        url: `/api/clinic/list?secret_token=${token}`,
        headers: {
          "Content-Type": "application/json",
        },
      };

      let result = await axios(config);
      self.clinics = result.data.clinics;

      self.loading = false;
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  },

  methods: {
    async onClinicChange() {
      const self = this;

      const token = cookieWrapper.getToken(self);

      const config = {
        method: "get",
        url: `/api/landing/visits-per-clinic?secret_token=${token}&clinicId=${self.selectedClinic}`,
        headers: {
          "Content-Type": "application/json",
        },
      };

      const result = await axios(config);
      self.model = result.data;
    },
  },
};
</script>

        
