<template>
  <div style="display: contents">
    <v-row>
      <v-col>
        <label style="font-size: 1.2em"
          >Interpretation Type
          <span class="required"><strong>(required)</strong></span></label
        >
        <v-row no-gutters>
          <v-radio-group v-model="interpretationTypeId" row
            ><v-radio
              v-for="(item, index) in interpretation_types"
              :key="index"
              :label="item.name"
              :value="item.id"
              :disabled="disabled"
              :readonly="readonly"
            ></v-radio
          ></v-radio-group>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="interpretationTypeId != -1">
      <v-col>
        <label style="font-size: 1.2em"
          >Service Type
          <span class="required"><strong>(required)</strong></span></label
        >
        <v-row no-gutters>
          <v-radio-group v-model="serviceTypeId"
            ><v-radio
              v-for="(item, index) in service_types"
              :key="index"
              :label="item.name"
              :value="item.id"
              :disabled="disabled"
              :readonly="readonly"
            ></v-radio
          ></v-radio-group>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "UltraTypeTreeReadOnly",
  data() {
    return {
      interpretation_types: [],
      service_types: [],
    };
  },

  props: {
    ultroTypeId: Number,
    interpretationTypeId: Number,
    serviceTypeId: Number,
    lookups: Object,
    disabled: Boolean,
    readonly: Boolean,
  },

  mounted() {},

  watch: {
    lookups() {
      const self = this;

      const ultro_type = self.lookups.ultro_types.filter((item) => {
        return item.id == self.ultroTypeId;
      })[0];

      self.interpretation_types = self.lookups.interpretation_types.filter(
        (item) => {
          return ultro_type.interp_types.includes(item.id);
        }
      );

      const interpretation_type = self.lookups.interpretation_types.filter(
        (item) => {
          return item.id == self.interpretationTypeId;
        }
      )[0];

      self.service_types = self.lookups.service_types.filter((item) => {
        return interpretation_type.services.includes(item.id);
      });
    },
  },
};
</script>
