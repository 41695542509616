<template>
  <v-container>
    <v-progress-linear
      class="mt-2"
      :active="loading"
      :indeterminate="loading"
      absolute
      top
      color="indigo accent-4"
    ></v-progress-linear>
    <div v-if="clinic">
      <v-row align="center" justify="center">
        <v-col cols="12" sm="5" md="7">
          <v-card class="elevation-8" :style="borderStyle">
            <v-card-text>
              <v-form>
                <h2 class="mb-3">{{ clinic.name }}</h2>
                <v-row v-show="!$store.state.isUserStaff">
                  <v-col cols="3"
                    ><v-switch v-model="edit_mode" label="Edit"></v-switch
                  ></v-col>
                  <v-col col="8">
                    <v-switch
                      :color="approved_color"
                      v-model="clinicApproved"
                      :label="approved_label"
                      title="If toggle is set to Off, clinic's login is disabled!"
                    ></v-switch>
                  </v-col>
                </v-row>
                <v-row
                  v-if="clinic.approved"
                  v-show="!$store.state.isUserStaff"
                >
                  <v-col col="8">
                    <v-btn small text color="indigo" @click="onSendPasswordLink"
                      >Send password link</v-btn
                    >
                    <span><small>(Last sent "todo")</small></span>
                  </v-col>

                  <v-col col="8">
                    <v-btn
                      small
                      text
                      color="deep-orange darken-4"
                      @click="onDeleteClinic"
                      >Delete Clinic</v-btn
                    >
                  </v-col>
                </v-row>
                <div style="height: 15px"></div>
                <v-text-field
                  v-model="clinic.name"
                  label="Name"
                  required
                  :disabled="!edit_mode"
                >
                  <template #label>
                    Name
                    <span class="required"><strong>(required)</strong></span>
                  </template>
                </v-text-field>
                <v-text-field
                  v-model="clinic.email"
                  label="Email"
                  required
                  :disabled="!edit_mode"
                >
                  <template #label>
                    Email
                    <span class="required"><strong>(required)</strong></span>
                  </template>
                </v-text-field>
                <v-text-field
                  v-model="clinic.email_billing"
                  label="Billing email"
                  required
                  :disabled="!edit_mode"
                ></v-text-field>
                <v-text-field
                  v-model="clinic.phone"
                  label="Phone"
                  required
                  :disabled="!edit_mode"
                >
                  <template #label>
                    Phone
                    <span class="required"><strong>(required)</strong></span>
                  </template>
                </v-text-field>
                <v-text-field
                  v-model="clinic.fax"
                  label="Fax"
                  required
                  :disabled="!edit_mode"
                ></v-text-field>
                <h2 class="mt-3 mb-1">Address</h2>
                <v-text-field
                  v-model="clinic.address.address1"
                  label="Address Line 1"
                  required
                  :disabled="!edit_mode"
                >
                  <template #label>
                    Address Line 1
                    <span class="required"><strong>(required)</strong></span>
                  </template>
                </v-text-field>
                <v-text-field
                  v-model="clinic.address.address2"
                  label="Address Line 2"
                  required
                  :disabled="!edit_mode"
                ></v-text-field>
                <v-text-field
                  v-model="clinic.address.city"
                  label="City"
                  required
                  :disabled="!edit_mode"
                >
                  <template #label>
                    City
                    <span class="required"><strong>(required)</strong></span>
                  </template>
                </v-text-field>
                <v-text-field
                  v-model="clinic.address.province.name"
                  label="Province"
                  required
                  disabled
                ></v-text-field>
                <v-text-field
                  v-model="clinic.address.postalCode"
                  label="Postal code"
                  required
                  :disabled="!edit_mode"
                >
                  <template #label>
                    Postal code
                    <span class="required"><strong>(required)</strong></span>
                  </template>
                </v-text-field>
                <h2 class="mt-3 mb-1">Director / Head veterinarian</h2>
                <v-text-field
                  v-if="clinic.director"
                  v-model="clinic.director.firstName"
                  label="First name"
                  required
                  :disabled="!edit_mode"
                ></v-text-field>
                <v-text-field
                  v-if="clinic.director"
                  v-model="clinic.director.lastName"
                  label="Last name"
                  required
                  :disabled="!edit_mode"
                ></v-text-field>
              </v-form>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  style="margin-left: 20px"
                  color="primary"
                  v-on:click="update"
                  v-show="edit_mode"
                  >Update</v-btn
                >
              </v-card-actions>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <v-dialog v-if="clinic" v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline"> Attention! </v-card-title>
        <v-card-text
          >You are about to change login status for clinic {{ clinic.name }}.
          Proceed?</v-card-text
        >
        <v-card-actions>
          <v-btn color="primary" text @click="dialogCancel()"> Cancel </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogProceed()"> Proceed </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
import cookieWrapper from "../../auth/cookieWrapper";
import dateTimeFormatter from "../../util/dateTimeFormatter";

export default {
  name: "AdminManageClinic",
  title: "HU Portal - Manage Clinic",
  props: {
    id: String,
  },
  data() {
    return {
      clinic: null,
      loading: true,
      edit_mode: false,
      dialog: false,
      clinicApproved: false,
    };
  },

  async mounted() {
    let self = this;

    try {
      const token = cookieWrapper.getToken(self);

      const config = {
        method: "get",
        url: `/api/clinic/${self.id}?secret_token=${token}`,
        headers: {
          "Content-Type": "application/json",
        },
      };

      const result = await axios(config);
      self.clinic = result.data;
      self.clinicApproved = self.clinic.approved;
      //TODO: we really need a model returned: missing approvedDate
      console.log(result.data);
    } catch (err) {
      console.log(err);
    } finally {
      self.loading = false;
    }
  },

  methods: {
    date_formatted(d) {
      return dateTimeFormatter.formatDateTime(d);
    },

    async update() {
      let self = this;

      try {
        const token = cookieWrapper.getToken(self);

        const payload = {
          id: self.clinic._id,
          name: self.clinic.name,
          email: self.clinic.email,
          email_billing: self.clinic.email_billing,
          phone: self.phone,
          fax: self.fax,

          director: {
            firstName: self.clinic.director.firstName,
            lastName: self.clinic.director.lastName,
          },

          address: {
            address1: self.clinic.address.address1,
            address2: self.clinic.address.address2,
            city: self.clinic.address.city,
            province: { id: 7, name: "Ontario" }, //Ontario, for now it's hardcoded
            postalCode: self.clinic.address.postalCode,
          },
        };

        const config = {
          method: "post",
          url: `/api/clinic/admin_update?secret_token=${token}`,
          headers: {
            "Content-Type": "application/json",
          },
          data: payload,
        };

        const result = await axios(config);
        console.log(result);

        alert("Clinic information was updated!");
      } catch (error) {
        console.log(error);
      }
    },

    async dialogProceed() {
      const self = this;
      try {
        //update approved flag for the clinic

        const token = cookieWrapper.getToken(self);

        const payload = JSON.stringify({
          email: self.clinic.email,
          name: self.clinic.name,
          approved: self.clinicApproved,
        });

        const config = {
          method: "post",
          url: `/api/clinic/updateApproved/${self.clinic._id}?secret_token=${token}`,
          headers: {
            "Content-Type": "application/json",
          },
          data: payload,
        };

        const result = await axios(config);
        console.log(result);
      } catch (error) {
        console.log(error);
      } finally {
        self.dialog = false;

        self.clinic.approved = self.clinicApproved;
      }
    },

    dialogCancel() {
      this.dialog = false;
      this.clinicApproved = !this.clinicApproved;
    },

    async onDeleteClinic() {
      const actionResult = confirm(
        `Are you sure you want to delete clinic ${this.clinic.name}? All patients, visits and documents will be deleted! This action is non-reversible!!!`
      );

      if (!actionResult) return;

      let self = this;

      try {
        const token = cookieWrapper.getToken(self);

        let data = JSON.stringify({
          clinicId: self.clinic._id,
          clinicName: self.clinic.name,
        });

        let config = {
          method: "delete",
          url: `/api/clinic/deleteClinic/?secret_token=${token}`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };

        let result = await axios(config);
        console.log(result);

        alert("Delete successful!");

        self.$router.push({
          name: "admin-manage-clinics",
        });
      } catch (err) {
        console.log(err);
        alert("Failed to delete clinic!");
      }
    },

    async onSendPasswordLink() {
      let self = this;

      try {
        const token = cookieWrapper.getToken(self);

        //generate link
        let data = JSON.stringify({
          clinicId: self.clinic._id,
          email: self.clinic.email,
        });

        let config = {
          method: "put",
          url: `/api/create-reset-password-link`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };

        const resetLink = await axios(config);
        console.log(resetLink);

        data = JSON.stringify({
          email: self.clinic.email,
          name: self.clinic.name,
          token: resetLink.data.uniqueId,
        });

        config = {
          method: "post",
          url: `/api/clinic/sendWelcomeEmail/?secret_token=${token}`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };

        //first send an email
        let result = await axios(config);
        alert(`Success! Password: ${result.data}`);
        console.log(result);
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
  },

  computed: {
    approved_color() {
      if (this.clinic.approved) return "green";
      else return "red";
    },
    approved_label() {
      if (this.clinic.approved) {
        return `Approved (${this.date_formatted(this.clinic.dateApproved)})`;
      }

      return "Approved";
    },
    borderStyle() {
      if (!this.clinic.approved) {
        return { border: "1px red solid" };
      }
      return "";
    },
  },

  watch: {
    clinicApproved() {
      if (this.clinicApproved == this.clinic.approved) return;
      this.dialog = true;
    },
  },
};
</script>
