<template>
  <div style="display: contents">
    <v-row>
      <v-col>
        <label style="font-size: 1.2em"
          >Type of Bicavity Ultrasound
          <span class="required"><strong>(required)</strong></span></label
        >
        <v-row no-gutters>
          <v-radio-group v-model="typeOfUltraId" row
            ><v-radio
              label="Abdominal and Cardiac / Ultrasound"
              value="1"
            ></v-radio
            ><v-radio label="Other" value="2"></v-radio
          ></v-radio-group>
        </v-row> </v-col
    ></v-row>
    <v-row v-if="typeOfUltraId == 2">
      <v-col>
        <v-textarea auto-grow v-model="otherUltraTypeText"
          ><template #label
            >Specify the type of bicavity ultrasound being requested
            <span class="required"><strong>(required)</strong></span></template
          ></v-textarea
        >
      </v-col>
    </v-row>

    <div class="abdominal" v-if="typeOfUltraId == 1">
      <v-row v-if="typeOfUltraId == 1">
        <v-col>
          <label style="font-size: 1.2em"
            >Type of Interpretation
            <span class="required"><strong>(required)</strong></span></label
          >
          <v-row no-gutters>
            <v-radio-group v-model="ultroTypeIdAbdominal" row
              ><v-radio label="Abdominal" value="3"></v-radio
            ></v-radio-group>
          </v-row> </v-col
      ></v-row>

      <v-row v-if="typeOfUltraId == 1 && ultroTypeIdAbdominal != -1">
        <v-col
          ><v-row>
            <v-col>
              <label style="font-size: 1.2em"
                >Interpretation Type
                <span class="required"><strong>(required)</strong></span></label
              >
              <v-row no-gutters>
                <v-radio-group v-model="interpretationTypeIdAbdominal" row
                  ><v-radio
                    v-for="(item, index) in interpretation_types_abdominal"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                  ></v-radio
                ></v-radio-group>
              </v-row>
            </v-col>
          </v-row>
          <label
            v-if="interpretationTypeIdAbdominal == -1"
            class="alert red darken-2 white--text"
            >Select interpretation type to proceed!</label
          >

          <v-row v-if="interpretationTypeIdAbdominal != -1">
            <v-col>
              <label style="font-size: 1.2em"
                >Service Type
                <span class="required"><strong>(required)</strong></span></label
              >
              <v-row no-gutters>
                <v-radio-group v-model="serviceTypeIdAbdominal"
                  ><v-radio
                    v-for="(item, index) in service_types_abdominal"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                  ></v-radio
                ></v-radio-group>
              </v-row>
            </v-col>
          </v-row>
          <label
            v-if="
              serviceTypeIdAbdominal == -1 &&
              interpretationTypeIdAbdominal != -1
            "
            class="alert red darken-2 white--text"
            >Select service type to proceed!</label
          >
        </v-col>
      </v-row>
    </div>

    <div class="cardiac" v-if="typeOfUltraId == 1">
      <v-row v-if="typeOfUltraId == 1">
        <v-col>
          <label style="font-size: 1.2em"
            >Type of Interpretation
            <span class="required"><strong>(required)</strong></span></label
          >
          <v-row no-gutters>
            <v-radio-group v-model="ultroTypeIdCardiac" row
              ><v-radio label="Cardiac" value="2"></v-radio
            ></v-radio-group>
          </v-row> </v-col
      ></v-row>

      <v-row v-if="typeOfUltraId == 1 && ultroTypeIdCardiac != -1">
        <v-col
          ><v-row>
            <v-col>
              <label style="font-size: 1.2em"
                >Interpretation Type
                <span class="required"><strong>(required)</strong></span></label
              >
              <v-row no-gutters>
                <v-radio-group v-model="interpretationTypeIdCardiac" row
                  ><v-radio
                    v-for="(item, index) in interpretation_types_cardiac"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                  ></v-radio
                ></v-radio-group>
              </v-row>
            </v-col>
          </v-row>
          <v-row v-if="interpretationTypeIdCardiac != -1">
            <v-col>
              <label style="font-size: 1.2em"
                >Service Type
                <span class="required"><strong>(required)</strong></span></label
              >
              <v-row no-gutters>
                <v-radio-group v-model="serviceTypeIdCardiac"
                  ><v-radio
                    v-for="(item, index) in service_types_cardiac"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                  ></v-radio
                ></v-radio-group>
              </v-row>
            </v-col>
          </v-row>
          <label
            v-if="
              serviceTypeIdCardiac == -1 && interpretationTypeIdCardiac != -1
            "
            class="alert red darken-2 white--text"
            >Select service type to proceed!</label
          >
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
export default {
  name: "UltrosoundTypeTreeBicavity",
  data() {
    return {
      interpretationTypeIdAbdominal: -1,
      interpretationTypeIdCardiac: -1,
      serviceTypeIdAbdominal: -1,
      serviceTypeIdCardiac: -1,
      interpretation_types_abdominal: [],
      interpretation_types_cardiac: [],
      service_types_abdominal: [],
      service_types_cardiac: [],
      typeOfUltraId: -1,
      otherUltraTypeText: "",
      ultroTypeIdAbdominal: -1,
      ultroTypeIdCardiac: -1,
    };
  },
  validations: {
    typeOfUltraId: {
      required,
      mustBeSelected: function (value) {
        if (value == -1) {
          return false;
        }

        return true;
      },
    },

    interpretationTypeIdAbdominal: {
      required,
      mustBeSelected: function (value) {
        if (this.typeOfUltraId == 2 || this.typeOfUltraId == -1) {
          return true;
        }

        if (value != -1 && value != "") {
          return true;
        }

        return false;
      },
    },
    interpretationTypeIdCardiac: {
      required,
      mustBeSelected: function (value) {
        if (this.typeOfUltraId == 2 || this.typeOfUltraId == -1) {
          return true;
        }

        if (value != -1 && value != "") {
          return true;
        }

        return false;
      },
    },
    serviceTypeIdAbdominal: {
      required,
      mustBeSelected: function (value) {
        if (this.typeOfUltraId == 2 || this.typeOfUltraId == -1) {
          return true;
        }

        if (value != -1 && value != "") {
          return true;
        }

        return false;
      },
    },
    serviceTypeIdCardiac: {
      required,
      mustBeSelected: function (value) {
        if (this.typeOfUltraId == 2 || this.typeOfUltraId == -1) {
          return true;
        }

        if (value != -1 && value != "") {
          return true;
        }

        return false;
      },
    },
    otherUltraTypeText: {
      mustBeSelected: function (value) {
        if (this.typeOfUltraId == 1 || this.typeOfUltraId == -1) {
          return true;
        }

        if (value) {
          return true;
        } else {
          return false;
        }
      },
    },
  },
  props: {
    lookups: Object,
  },
  mounted() {
    const self = this;

    self.ultroTypeIdAbdominal = "3";
    self.ultroTypeIdCardiac = "2";

    this.$emit("childIsReady");
  },
  watch: {
    typeOfUltraId(newVal) {
      const self = this;

      self.interpretationTypeIdAbdominal = -1;
      self.interpretationTypeIdCardiac = -1;
      self.serviceTypeIdAbdominal = -1;
      self.serviceTypeIdCardiac = -1;

      if (newVal == 1) {
        self.interpretationTypeIdCardiac = 3;
      }
    },

    ultroTypeIdAbdominal() {
      const self = this;

      const ultro_type = self.lookups.ultro_types.filter((item) => {
        return item.id == self.ultroTypeIdAbdominal;
      })[0];
      console.log(ultro_type);

      self.interpretation_types_abdominal =
        self.lookups.interpretation_types.filter((item) => {
          //console.log(item);
          return ultro_type.interp_types.includes(item.id);
        });

      if (self.interpretation_types_abdominal.length == 1) {
        self.interpretationTypeIdAbdominal =
          self.interpretation_types_abdominal[0].id;
      } else {
        self.interpretationTypeIdAbdominal = -1;
      }

      self.serviceTypeIdAbdominal = -1;
    },

    ultroTypeIdCardiac() {
      const self = this;

      const ultro_type = self.lookups.ultro_types.filter((item) => {
        return item.id == self.ultroTypeIdCardiac;
      })[0];
      console.log(ultro_type);

      self.interpretation_types_cardiac =
        self.lookups.interpretation_types.filter((item) => {
          //console.log(item);
          return ultro_type.interp_types.includes(item.id);
        });

      if (self.interpretation_types_cardiac.length == 1) {
        self.interpretationTypeIdCardiac =
          self.interpretation_types_cardiac[0].id;
      } else {
        self.interpretationTypeIdCardiac = -1;
      }

      self.serviceTypeIdCardiac = -1;
    },

    interpretationTypeIdAbdominal(newVal) {
      if (newVal == -1) return;

      const self = this;

      const interpretation_type = self.lookups.interpretation_types.filter(
        (item) => {
          return item.id == newVal;
        }
      )[0];

      self.service_types_abdominal = self.lookups.service_types.filter(
        (item) => {
          //console.log(item);
          return interpretation_type.services.includes(item.id);
        }
      );

      self.serviceTypeIdAbdominal = -1;
    },

    interpretationTypeIdCardiac(newVal) {
      if (newVal == -1) return;

      const self = this;

      const interpretation_type = self.lookups.interpretation_types.filter(
        (item) => {
          return item.id == newVal;
        }
      )[0];

      self.service_types_cardiac = self.lookups.service_types.filter((item) => {
        //console.log(item);
        return interpretation_type.services.includes(item.id);
      });

      self.serviceTypeIdCardiac = -1;
    },
  },
};
</script>

<style scoped>
div.abdominal {
  padding-left: 20px;
  border: 1px solid grey;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 5px;
}

div.cardiac {
  padding-left: 20px;
  border: 1px solid grey;
  padding-top: 5px;
  padding-bottom: 5px;
}
</style>
