<template>
  <v-container>
    <v-progress-linear
      class="mt-2"
      :active="loading"
      :indeterminate="loading"
      absolute
      top
      color="indigo accent-4"
    ></v-progress-linear>
    <v-row align="center" justify="center">
      <h1>Clinics</h1>
    </v-row>

    <v-row align="center" justify="center" v-show="!$store.state.isUserStaff">
      <v-col md="12" sm="5" lg="12" col="12">
        <v-sheet class="mt-2 mb-2"
          ><v-btn
            large
            color="primary"
            @click="$router.push(`/home/admin-register-clinic`)"
          >
            Register new clinic
          </v-btn></v-sheet
        ></v-col
      >
    </v-row>

    <v-row align="center" justify="center">
      <v-col md="12" sm="5" lg="12" col="12">
        <v-row align="center" justify="center" class="mb-3">
          <h2>
            Approved clinics
            <v-avatar color="green darken-4" size="45">
              <span class="white--text">{{ approved_clinics.length }}</span>
            </v-avatar>
          </h2>
        </v-row>

        <v-data-table
          show-expand
          :loading="loading"
          loading-text="Loading... Please wait"
          item-key="clinic._id"
          :single-expand="false"
          :headers="headers"
          :items="approved_clinics"
          :items-per-page="itemsPerPage"
          :footer-props="{
            'items-per-page-options': [10, 50, 100, -1],
          }"
          :sort-by="['clinic.dateCreated']"
          :sort-desc="[true]"
          @item-expanded="onRowExpand"
          :item-class="calculateRowCSSClass"
          :search="search"
        >
          <template v-slot:top>
            <v-text-field
              v-model="search"
              label="Search"
              class="mx-4"
            ></v-text-field>
          </template>
          <template v-slot:[`item.clinic.dateCreated`]="{ item }">
            {{ formatdDate(item.clinic.dateCreated) }}</template
          >

          <template v-slot:[`item.clinic.dateApproved`]="{ item }">
            {{ formatdDate(item.clinic.dateApproved) }}</template
          >
          <template v-slot:[`item.edit`]="{ item }">
            <v-icon
              title="Edit clinic"
              color="blue darken-2"
              @click="
                $router.push(`/home/admin-manage-clinic/${item.clinic._id}`)
              "
            >
              mdi-pencil
            </v-icon></template
          >

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" style="padding: 15px">
              <table
                role="presentation"
                style="width: 90%; padding-left: 10px"
                v-if="item && item.patients && item.patients.length > 0"
              >
                <caption>
                  Clinic's patients
                </caption>
                <tr style="text-align: left">
                  <th>Name</th>
                  <th>Breed</th>
                  <th>Species</th>
                  <th>Gender</th>
                  <th>Visits</th>
                  <td></td>
                </tr>
                <tr v-for="i in item.patients" :key="i._id">
                  <td>
                    <a :href="`/home/manage-patient/${i._id}`"
                      >{{ i.name }} {{ i.lastName }}
                    </a>
                  </td>
                  <td>{{ i.breed }}</td>
                  <td>{{ i.species.name }}</td>
                  <td>{{ i.gender.name }}</td>
                  <td>{{ i.visit_count }}</td>
                  <td>
                    <v-icon
                      title="View visits"
                      color="blue darken-2"
                      @click="
                        $router.push(`/home/admin-patient-visits/${i._id}`)
                      "
                    >
                      mdi-clipboard-account
                    </v-icon>
                  </td>
                </tr>
              </table>

              <span v-if="item && item.patients && item.patients.length == 0"
                >no patients yet</span
              >
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row align="center" justify="center">
      <v-col md="12" sm="5" lg="12" col="12">
        <v-row align="center" justify="center" class="mb-3"
          ><h2>
            Not approved clinics
            <v-avatar color="red darken-2" size="45">
              <span class="white--text">{{ not_approved_clinics.length }}</span>
            </v-avatar>
          </h2></v-row
        >
        <v-data-table
          show-expand
          item-key="clinic._id"
          :single-expand="false"
          :headers="headers"
          :items="not_approved_clinics"
          :items-per-page="itemsPerPage"
          :sort-by="['clinic.dateCreated']"
          :sort-desc="[true]"
        >
          <template v-slot:[`item.clinic.dateCreated`]="{ item }">
            {{ formatdDate(item.clinic.dateCreated) }}</template
          >

          <template v-slot:[`item.clinic.dateApproved`]="{ item }">
            {{ formatdDate(item.clinic.dateApproved) }}</template
          >
          <template v-slot:[`item.edit`]="{ item }">
            <v-icon
              title="Edit clinic"
              color="blue darken-2"
              @click="
                $router.push(`/home/admin-manage-clinic/${item.clinic._id}`)
              "
            >
              mdi-pencil
            </v-icon></template
          >

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" style="padding: 15px">
              <div v-if="item && item.patients && item.patients.length > 0">
                Clinic's patients
              </div>
              <table
                width="100%"
                v-if="item && item.patients && item.patients.length > 0"
              >
                <caption>
                  Clinic's patients
                </caption>
                <tr style="text-align: left">
                  <th>Name</th>
                  <th>Breed</th>
                  <th>Species</th>
                  <th>Gender</th>
                </tr>
                <tr v-for="i in item.patients" :key="i._id">
                  <td>
                    <a :href="`/home/manage-patient/${i._id}`"
                      >{{ i.name }} {{ i.lastName }}
                    </a>
                  </td>
                  <td>{{ i.breed }}</td>
                  <td>{{ i.species.name }}</td>
                  <td>{{ i.gender.name }}</td>
                </tr>
              </table>

              <span v-if="item && item.patients && item.patients.length == 0"
                >no patients yet</span
              >
            </td>
          </template>
        </v-data-table>
      </v-col></v-row
    >
  </v-container>
</template>

<script>
import axios from "axios";
import cookieWrapper from "../../auth/cookieWrapper";
import dateTimeFormatter from "../../util/dateTimeFormatter";
export default {
  name: "AdminManageClinics",
  title: "HU Portal - Clinics",

  data() {
    return {
      headers: [
        { text: "", value: "data-table-expand" },
        {
          text: "Name",
          value: "clinic.name",
          width: "30%",
        },
        { text: "Email", value: "clinic.email", width: "25%" },
        { text: "Date created", value: "clinic.dateCreated" },
        { text: "Approval date", value: "clinic.dateApproved" },
        { text: "", value: "edit", filterable: false, sortable: false },
      ],
      clinics: [],
      loading: true,
      itemsPerPage: 10,
      search: "",
    };
  },

  async mounted() {
    let self = this;

    try {
      const token = cookieWrapper.getToken(self);

      let config = {
        method: "get",
        url: `/api/clinic/all-light?secret_token=${token}`,
        headers: {
          "Content-Type": "application/json",
        },
      };

      let result = await axios(config);
      self.clinics = result.data;

      console.log(result.data);
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  },

  computed: {
    approved_clinics() {
      return this.clinics.filter((i) => {
        return i.clinic.approved;
      });
    },
    not_approved_clinics() {
      return this.clinics.filter((i) => {
        return !i.clinic.approved;
      });
    },
  },

  methods: {
    date_formatted(d) {
      return dateTimeFormatter.formatDateTime(d);
    },
    formatdDate(d) {
      //return dateTimeFormatter.formatDateTime(d);
      return dateTimeFormatter.formatWithMask(d, "YYYY-MM-DD hh:mm A");
    },

    async onRowExpand({ item, value }) {
      let self = this;

      if (value != true) {
        return;
      }

      const token = cookieWrapper.getToken(self);

      let config = {
        method: "get",
        url: `/api/patient/byClinicId/${item.clinic._id}?secret_token=${token}`,
        headers: {
          "Content-Type": "application/json",
        },
      };

      const patients = await axios(config);

      item.patients = patients.data;
    },

    calculateRowCSSClass(item) {
      return item.hasPatients == true ? "hasPatients" : "noPatients";
    },
  },
};
</script>

<style>
caption {
  text-align: start;
  margin-bottom: 15px;
}
tr.hasPatients {
  background-color: lightgreen;
}
tr.noPatients {
  background-color: orange;
}
</style>
