<template>
  <v-container>
    <v-progress-linear
      class="mt-2"
      :active="loading"
      :indeterminate="loading"
      absolute
      top
      color="indigo accent-4"
    ></v-progress-linear>
    <v-row align="center" justify="center">
      <h1 class="mb-3">{{ pageTitle }}</h1>
    </v-row>
    <v-row align="center" justify="center">
      <v-col md="9" sm="5" col="12">
        <v-card class="elevation-8">
          <v-row>
            <v-col md="6" sm="6" col="6" align="left">
              <v-btn
                style="margin: 5px"
                color="red"
                small
                text
                @click="onDeletePatient"
                v-show="$store.state.isUserAdmin"
              >
                Delete Patient
              </v-btn></v-col
            >
          </v-row>

          <v-card-text>
            <v-form>
              <v-text-field
                v-model="lastName"
                label="Name"
                hint="minimum 2 characters"
                required
                :readonly="isReadOnly"
              >
                <template #label>
                  Last Name
                  <span class="required"><strong>(required)</strong></span>
                </template>
              </v-text-field>
              <v-text-field
                v-model="name"
                label="Name"
                hint="minimum 2 characters"
                required
                :readonly="isReadOnly"
              >
                <template #label>
                  Patient Name
                  <span class="required"><strong>(required)</strong></span>
                </template>
              </v-text-field>

              <v-row>
                <v-col>
                  <label style="font-size: 1.2em"
                    >Age
                    <span class="required"
                      ><strong>(required)</strong></span
                    ></label
                  >
                  <v-row no-gutters>
                    <v-col col="2" md="2" lg="2">
                      <v-text-field
                        type="number"
                        min="0"
                        max="200"
                        label="years"
                        v-model="age.years"
                        :readonly="isReadOnly"
                      ></v-text-field> </v-col
                    ><v-col col="2" md="2" lg="2"
                      ><v-text-field
                        type="number"
                        min="0"
                        max="12"
                        label="month"
                        v-model="age.months"
                        :readonly="isReadOnly"
                      ></v-text-field></v-col
                    ><v-col col="2" md="2" lg="2"
                      ><v-text-field
                        type="number"
                        min="0"
                        label="weeks"
                        v-model="age.weeks"
                        :readonly="isReadOnly"
                      ></v-text-field></v-col
                    ><v-col
                      ><v-checkbox
                        v-model="age.estimated"
                        label="estimated"
                        :readonly="isReadOnly"
                      ></v-checkbox
                    ></v-col> </v-row></v-col
              ></v-row>

              <v-row>
                <v-col>
                  <label style="font-size: 1.2em"
                    >Weight
                    <span class="required"
                      ><strong>(required)</strong></span
                    ></label
                  >
                  <v-row no-gutters>
                    <v-col col="3" md="3">
                      <v-radio-group
                        v-model="weightSelection"
                        row
                        :readonly="isReadOnly"
                      >
                        <v-radio label="Kg" value="1"></v-radio>
                        <v-radio label="Lb" value="2"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col col="3" md="3">
                      <v-text-field
                        aria-label="enter weight in killograms or pounds"
                        type="number"
                        min="1"
                        max="300"
                        v-model="weight"
                        :readonly="isReadOnly"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-select
                v-if="model"
                v-model="gender"
                item-text="name"
                item-value="id"
                :items="model.lookups.gender"
                label="Gender"
                :readonly="isReadOnly"
              >
                <template #label>
                  Gender
                  <span class="required"><strong>(required)</strong></span>
                </template>
              </v-select>

              <v-select
                v-if="model"
                v-model="species"
                item-text="name"
                item-value="id"
                :items="model.lookups.species"
                label="Species"
                @change="onSpeciesTypeChange"
                :readonly="isReadOnly"
              >
                <template #label>
                  Species
                  <span class="required"><strong>(required)</strong></span>
                </template>
              </v-select>

              <v-text-field
                v-model="species_other_text"
                v-if="showSpeciesOther"
                hint="Provide other species information"
                label="Other species information"
                :readonly="isReadOnly"
              >
                <template #label>
                  Other species information
                  <span class="required"><strong>(required)</strong></span>
                </template>
              </v-text-field>

              <v-text-field v-model="breed" label="Breed" required>
                <template #label>
                  Breed
                  <span class="required"><strong>(required)</strong></span>
                </template>
              </v-text-field>
              <v-card-actions justify="end" v-show="!$store.state.isUserAdmin">
                <v-btn
                  small
                  color="primary"
                  @click="$router.push(`/home/patients`)"
                >
                  Back
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  small
                  color="primary"
                  @click="onSubmit"
                  :disabled="$v.$invalid"
                >
                  Submit
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import cookieWrapper from "../auth/cookieWrapper";

export default {
  name: "ManagePatient",
  title: "HU Portal - Manage Patient",
  props: {
    id: String,
  },
  data() {
    return {
      loading: true,
      saving: false,
      name: "",
      lastName: "",
      breed: "",
      gender: "",
      species: "",
      age: { years: "", months: "", weeks: "", estimated: false },
      weight: "",
      weightSelection: -1,
      species_other_text: "",
      model: null,
      isReadOnly: false,
    };
  },
  validations: {
    name: {
      required,
      minLength: minLength(2),
    },
    lastName: {
      required,
      minLength: minLength(2),
    },

    gender: {
      required,
      mustBeSelected: function (value) {
        if (value != "") {
          return true;
        }

        return false;
      },
    },
    weight: {
      required,
      maxLenght: maxLength(5),
      mustBeSelected: function (value) {
        if (value != "") {
          return true;
        }

        return false;
      },
    },
    weightSelection: {
      required,
      mustBeSelected: function (value) {
        if (value != -1) {
          return true;
        }

        return false;
      },
    },
    age: {
      required,
      mustBeSelected: function () {
        if (
          (this.age.years == "" || this.age.years == "0") &&
          (this.age.months == "" || this.age.months == "0") &&
          (this.age.weeks == "" || this.age.weeks == "0")
        ) {
          return false;
        }

        return true;
      },
    },
    species: {
      required,
      mustBeSelected: function (value) {
        if (value != "") {
          return true;
        }

        return false;
      },
    },

    species_other_text: {
      conditionalSelection: function (value) {
        if (this.species == 1) {
          if (value) return true;
          else return false;
        }

        return true;
      },
    },

    breed: {
      required,
      minLength: minLength(3),
    },
  },
  async mounted() {
    let self = this;

    //existing form read-only
    if (self.$store.state.isUserAdmin) {
      self.isReadOnly = true;
    } else {
      self.isReadOnly = false;
    }

    try {
      const token = cookieWrapper.getToken(self);

      const config = {
        method: "get",
        url: `/api/patient/model/${self.id}?secret_token=${token}`,
        headers: {
          "Content-Type": "application/json",
        },
      };

      const result = await axios(config);
      self.model = result.data;
      console.log(result.data);

      //edit mode
      self.breed = self.model.patient.breed;
      self.gender = self.model.patient.gender.id;
      self.name = self.model.patient.name;
      self.lastName = self.model.patient.lastName;
      self.species = self.model.patient.species.id;
      self.age = self.model.patient.age;
      self.species_other_text = self.model.patient.species_other_text;

      if (self.model.patient.weight.weightKg != 0) {
        self.weight = self.model.patient.weight.weightKg;
        self.weightSelection = "1";
      } else if (self.model.patient.weight.weightLb != 0) {
        self.weight = self.model.patient.weight.weightLb;
        self.weightSelection = "2";
      }
    } catch (err) {
      self.$router.push("/application-error");
    } finally {
      this.loading = false;
    }
  },
  computed: {
    pageTitle() {
      if (this.id) return `Manage patient information`;
      else return "Add new patient";
    },

    showSpeciesOther() {
      if (this.species == 1) return true;

      return false;
    },
  },

  methods: {
    async onDeletePatient() {
      const self = this;
      self.saving = true;

      const answer = confirm(
        "This will permanantly delete patient, all patient's visits and documents! Proceed?"
      );

      if (!answer) return;

      const token = cookieWrapper.getToken(self);

      const payload = {
        id: self.id,
      };

      const config = {
        method: "delete",
        url: `/api/patient/delete?secret_token=${token}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(payload),
      };

      await axios(config);

      self.$router.push("/home/admin-landing");
    },
    async onSubmit() {
      const self = this;

      self.saving = true;

      const token = cookieWrapper.getToken(self);

      let config = null;

      let payload = {
        id: self.id,
        clinicId: self.$store.state.loggedInClinicId,
        name: self.name,
        lastName: self.lastName,
        age: {
          years: self.age.years || 0,
          months: self.age.months || 0,
          weeks: self.age.weeks || 0,
          estimated: self.age.estimated,
        },
        weight: {
          weightKg: self.weightSelection == 1 ? self.weight : 0,
          weightLb: self.weightSelection == 2 ? self.weight : 0,
        },
        breed: self.breed,
        gender: self.model.lookups.gender.filter((item) => {
          return item.id == self.gender;
        })[0],
        species: self.model.lookups.species.filter((item) => {
          return item.id == self.species;
        })[0],
        loggedInUser: self.$store.state.loggedInUser,
        species_other_text: self.species == 1 ? self.species_other_text : "",
      };

      try {
        config = {
          method: "post",
          url: `/api/patient/update/${self.id}?secret_token=${token}`,
          headers: {
            "Content-Type": "application/json",
          },
          data: payload,
        };

        await axios(config);
        //console.log(result);
        self.$router.push("/home/patients");
      } catch (err) {
        console.log(err);
        alert("Update failed!");
      }
    },
    confirmLeave() {
      return window.confirm(
        "Do you really want to leave? You may have unsaved changes!"
      );
    },

    onSpeciesTypeChange() {},
  },
  /*
  beforeRouteLeave(to, from, next) {
    //on save don't show warning
    if (this.saving) {
      next();
      return;
    }

    //on error do not show message
    if (to.name == "error") {
      next();
      return;
    }

    if (this.confirmLeave()) {
      // Navigate to next view
      next();
    } else {
      next(false);
    }
  },*/
};
</script>

