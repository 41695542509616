var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('h1',[_vm._v("Registered Patients")])]),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"md":"12","sm":"5","lg":"12","col":"12"}},[_c('v-sheet',{staticClass:"mt-2 mb-2"},[_c('v-btn',{attrs:{"large":"","color":"primary"},on:{"click":function($event){return _vm.$router.push("/home/create-patient")}}},[_vm._v(" Add new patient ")])],1)],1)],1),_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"md":"12","sm":"5","lg":"12","col":"12"}},[_c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-magnify","label":"Search / Edit existing patients","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.patients,"search":_vm.search,"items-per-page":_vm.itemsPerPage,"loading":_vm.loading,"loading-text":"Loading... Please wait","sort-by":_vm.sortBy,"expanded":_vm.itemExpanded,"show-expand":"","single-expand":"","item-key":"_id"},on:{"update:expanded":function($event){_vm.itemExpanded=$event},"item-expanded":_vm.onRowExpand},scopedSlots:_vm._u([{key:"item.dateCreated",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatdDate(item.dateCreated)))]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"title":"Edit patient","color":"blue darken-2"},on:{"click":function($event){return _vm.$router.push(("/home/manage-patient/" + (item._id)))}}},[_vm._v(" mdi-account-edit ")])]}},{key:"item.manage-form",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"title":"Add new visit","color":"blue darken-2"},on:{"click":function($event){return _vm.$router.push(("/home/create-new-form/" + (item._id)))}}},[_vm._v(" mdi-clipboard ")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticStyle:{"padding":"15px"},attrs:{"colspan":headers.length}},[(!item.forms)?_c('div',[_vm._v("Loading..")]):_vm._e(),(item && item.forms && item.forms.length > 0)?_c('table',{staticStyle:{"width":"70%"}},[_c('caption',{staticStyle:{"text-align":"start","margin-bottom":"7px"}},[_vm._v(" Patient visit(s) ")]),_c('tr',{staticStyle:{"text-align":"left"}},[_c('th',[_vm._v("Visit date")]),_c('th',[_vm._v("Visit history")]),_c('td')]),_vm._l((item.forms),function(i){return _c('tr',{key:i._id},[_c('td',[_vm._v(_vm._s(_vm.formatdDate(i.dateCreated)))]),_c('td',[_vm._v(_vm._s(i.reason))]),_c('td',[_c('v-icon',{attrs:{"title":"Review patient visit","color":"blue darken-2"},on:{"click":function($event){return _vm.$router.push(
                          ("/home/patient-form/" + (item._id) + "/" + (i._id))
                        )}}},[_vm._v(" mdi-clipboard-edit ")])],1)])})],2):_vm._e(),(item && item.forms && item.forms.length == 0)?_c('span',[_vm._v("no visits yet")]):_vm._e()])]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }