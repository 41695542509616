
 <template>
  <div style="display: contents">
    <v-row>
      <v-col>
        <label style="font-size: 1.2em"
          >Type of Bicavity Ultrasound
          <span class="required"><strong>(required)</strong></span></label
        >
        <v-row no-gutters>
          <v-radio-group
            v-model="typeOfUltraId"
            row
            :disabled="disabled"
            :readonly="readonly"
            ><v-radio
              label="Abdominal and Cardiac / Ultrasound"
              value="1"
            ></v-radio
            ><v-radio label="Other" value="2"></v-radio
          ></v-radio-group>
        </v-row> </v-col
    ></v-row>
    <v-row v-if="typeOfUltraId == 2">
      <v-col>
        <v-textarea
          auto-grow
          v-model="otherUltraTypeText"
          :disabled="disabled"
          :readonly="readonly"
          ><template #label
            >Specify the type of bicavity ultrasound being requested
            <span class="required"><strong>(required)</strong></span></template
          ></v-textarea
        >
      </v-col>
    </v-row>

    <div class="abdominal" v-if="typeOfUltraId == 1">
      <v-row v-if="typeOfUltraId == 1">
        <v-col>
          <label style="font-size: 1.2em"
            >Type of Interpretation
            <span class="required"><strong>(required)</strong></span></label
          >
          <v-row no-gutters>
            <v-radio-group
              v-model="ultroTypeIdAbdominal"
              row
              :disabled="disabled"
              :readonly="readonly"
              ><v-radio label="Abdominal" value="3"></v-radio
            ></v-radio-group>
          </v-row> </v-col
      ></v-row>

      <v-row v-if="typeOfUltraId == 1 && ultroTypeIdAbdominal != -1">
        <v-col
          ><v-row>
            <v-col>
              <label style="font-size: 1.2em"
                >Interpretation Type
                <span class="required"><strong>(required)</strong></span></label
              >
              <v-row no-gutters>
                <v-radio-group
                  v-model="interpretationTypeIdAbdominal"
                  row
                  :disabled="disabled"
                  :readonly="readonly"
                  ><v-radio
                    v-for="(item, index) in interpretation_types_abdominal"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                  ></v-radio
                ></v-radio-group>
              </v-row>
            </v-col>
          </v-row>

          <v-row v-if="interpretationTypeIdAbdominal != -1">
            <v-col>
              <label style="font-size: 1.2em"
                >Service Type
                <span class="required"><strong>(required)</strong></span></label
              >
              <v-row no-gutters>
                <v-radio-group
                  v-model="serviceTypeIdAbdominal"
                  :disabled="disabled"
                  :readonly="readonly"
                  ><v-radio
                    v-for="(item, index) in service_types_abdominal"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                  ></v-radio
                ></v-radio-group>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>

    <div class="cardiac" v-if="typeOfUltraId == 1">
      <v-row v-if="typeOfUltraId == 1">
        <v-col>
          <label style="font-size: 1.2em"
            >Type of Interpretation
            <span class="required"><strong>(required)</strong></span></label
          >
          <v-row no-gutters>
            <v-radio-group
              v-model="ultroTypeIdCardiac"
              row
              :disabled="disabled"
              :readonly="readonly"
              ><v-radio label="Cardiac" value="2"></v-radio
            ></v-radio-group>
          </v-row> </v-col
      ></v-row>

      <v-row v-if="typeOfUltraId == 1 && ultroTypeIdCardiac != -1">
        <v-col
          ><v-row>
            <v-col>
              <label style="font-size: 1.2em"
                >Interpretation Type
                <span class="required"><strong>(required)</strong></span></label
              >
              <v-row no-gutters>
                <v-radio-group
                  v-model="interpretationTypeIdCardiac"
                  row
                  :disabled="disabled"
                  :readonly="readonly"
                  ><v-radio
                    v-for="(item, index) in interpretation_types_cardiac"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                  ></v-radio
                ></v-radio-group>
              </v-row>
            </v-col>
          </v-row>
          <v-row v-if="interpretationTypeIdCardiac != -1">
            <v-col>
              <label style="font-size: 1.2em"
                >Service Type
                <span class="required"><strong>(required)</strong></span></label
              >
              <v-row no-gutters>
                <v-radio-group
                  v-model="serviceTypeIdCardiac"
                  :disabled="disabled"
                  :readonly="readonly"
                  ><v-radio
                    v-for="(item, index) in service_types_cardiac"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                  ></v-radio
                ></v-radio-group>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "UltraTypeTreeBicavityReadOnly",
  data() {
    return {
      interpretationTypeIdAbdominal: -1,
      interpretationTypeIdCardiac: -1,
      serviceTypeIdAbdominal: -1,
      serviceTypeIdCardiac: -1,
      interpretation_types_abdominal: [],
      interpretation_types_cardiac: [],
      service_types_abdominal: [],
      service_types_cardiac: [],
      typeOfUltraId: -1,
      otherUltraTypeText: "",
      ultroTypeIdAbdominal: -1,
      ultroTypeIdCardiac: -1,
    };
  },

  props: {
    lookups: Object,
    bicavityModel: Object,
    disabled: Boolean,
    readonly: Boolean,
  },
  mounted() {
    const self = this;

    //self.bicavityModel = newVal;

    if (self.bicavityModel.abdominal_cardiac != undefined) {
      //abdominal or cardiac
      self.typeOfUltraId = "1";

      self.ultroTypeIdAbdominal = "3"; //Abdominal

      self.interpretationTypeIdAbdominal =
        self.bicavityModel.abdominal_cardiac.abdominal.interpretation_type.id;

      self.serviceTypeIdAbdominal =
        self.bicavityModel.abdominal_cardiac.abdominal.service_type.id;

      //Abdominal
      const ultro_type_abdominal = self.lookups.ultro_types.filter((item) => {
        return item.id == self.ultroTypeIdAbdominal;
      })[0];

      self.interpretation_types_abdominal =
        self.lookups.interpretation_types.filter((item) => {
          return ultro_type_abdominal.interp_types.includes(item.id);
        });

      const interpretation_type_abdominal =
        self.lookups.interpretation_types.filter((item) => {
          return item.id == self.interpretationTypeIdAbdominal;
        })[0];

      self.service_types_abdominal = self.lookups.service_types.filter(
        (item) => {
          return interpretation_type_abdominal.services.includes(item.id);
        }
      );

      self.ultroTypeIdCardiac = "2"; //Cardiac

      self.interpretationTypeIdCardiac =
        self.bicavityModel.abdominal_cardiac.cardiac.interpretation_type.id;

      self.serviceTypeIdCardiac =
        self.bicavityModel.abdominal_cardiac.cardiac.service_type.id;

      //Cardiac
      const ultro_type_cardiac = self.lookups.ultro_types.filter((item) => {
        return item.id == self.ultroTypeIdCardiac;
      })[0];

      self.interpretation_types_cardiac =
        self.lookups.interpretation_types.filter((item) => {
          return ultro_type_cardiac.interp_types.includes(item.id);
        });

      const interpretation_type_cardiac =
        self.lookups.interpretation_types.filter((item) => {
          return item.id == self.interpretationTypeIdCardiac;
        })[0];

      self.service_types_cardiac = self.lookups.service_types.filter((item) => {
        return interpretation_type_cardiac.services.includes(item.id);
      });
    } else {
      //other
      self.typeOfUltraId = "2";
      self.otherUltraTypeText = self.bicavityModel.other.other_text;
    }
  },
};
</script>

<style scoped>
div.abdominal {
  padding-left: 20px;
  border: 1px solid grey;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 5px;
}

div.cardiac {
  padding-left: 20px;
  border: 1px solid grey;
  padding-top: 5px;
  padding-bottom: 5px;
}
</style>
