import moment from "moment";

class dateTimeFormatter {
  constructor() {}

  static formatDateTime(original_date, locale) {
    if (!original_date) return "";
    let formatted = "";
    if (locale) {
      formatted =
        new Date(original_date).toLocaleDateString(locale) +
        " " +
        new Date(original_date).toLocaleTimeString(locale);
    } else {
      formatted =
        new Date(original_date).toLocaleDateString("en-US") +
        " " +
        new Date(original_date).toLocaleTimeString("en-US");
    }

    return formatted;
  }

  static formatWithMask(original_date, mask) {
    if (!original_date) return "";

    const d = moment(original_date);

    return d.format(mask);
  }
}

export default dateTimeFormatter;
