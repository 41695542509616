<template>
  <v-app>
    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="blue darken-3"
      dark
    >
      <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
        <span class="hidden-sm-and-down">Hound Ultrasound Portal</span>
      </v-toolbar-title>
    </v-app-bar>
    <v-main>
      <v-container>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="5">
            <v-card class="elevation-8">
              <v-card-text>
                <v-form v-if="token">
                  <h1 class="mb-6">Welcom!</h1>
                  <h2>Create password to start using HU Portal</h2>
                  <v-text-field
                    v-model="clinicEmail"
                    placeholder="your clinic's email"
                    label="Email"
                    required
                  >
                    <template #label>
                      Email
                      <span class="required"><strong>(required)</strong></span>
                    </template>
                  </v-text-field>
                  <v-text-field
                    v-model="password"
                    type="password"
                    label="Password"
                    required
                  >
                    <template #label>
                      Password
                      <span class="required"><strong>(required)</strong></span>
                    </template>
                  </v-text-field>
                  <v-text-field
                    v-model="password_confirm"
                    type="password"
                    label="Confirm Password"
                    required
                  >
                    <template #label>
                      Confirm password
                      <span class="required"><strong>(required)</strong></span>
                    </template>
                  </v-text-field>
                </v-form>
                <v-form v-if="!token">
                  <h1 class="mb-6">Password reset</h1>
                  <v-text-field
                    v-model="clinicEmail"
                    placeholder="your clinic's email"
                    label="Email"
                    required
                  >
                    <template #label>
                      Email
                      <span class="required"><strong>(required)</strong></span>
                    </template>
                  </v-text-field>
                </v-form>
                <v-card-actions>
                  <v-btn
                    text
                    style="margin-left: 20px"
                    color="primary"
                    v-on:click="backToLogin"
                    >Back to login</v-btn
                  >
                  <v-spacer></v-spacer>
                  <v-btn
                    style="margin-left: 20px"
                    color="primary"
                    v-on:click="submit"
                    >Submit</v-btn
                  >
                </v-card-actions>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline"> Congratulations! </v-card-title>
        <v-card-text
          >You have succesfully registered for HU portal. After administrator
          approves your registration, login information will be emailed to you
          at {{ this.clinicEmail }}</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="backToLogin()"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import axios from "axios";
export default {
  name: "ResetPassword",
  title: "HU Portal - Reset Password",
  data() {
    return {
      clinicEmail: "",
      password: "",
      password_confirm: "",
      dialog: false,
    };
  },
  props: {
    token: String,
  },
  mounted() {},
  methods: {
    backToLogin() {
      //navigate to Login
      this.$router.push({
        name: "login",
      });
    },
    async submit() {
      let self = this;
      if (self.token) {
        //we got token: lets change the password
        const payload = {
          token: self.token,
          email: self.clinicEmail,
          password: self.password,
        };
        let config = {
          method: "post",
          url: "/api/change-password",
          headers: {
            "Content-Type": "application/json",
          },
          data: payload,
        };
        let result = await axios(config);

        this.$router.push({
          name: "login",
        });

        console.log(result);
      } else {
        //no token: send password reset link

        //first generate password-reset collection entry
        let config = {
          method: "put",
          url: `/api/create-reset-password-link`,
          headers: {
            "Content-Type": "application/json",
          },
          data: JSON.stringify({ email: self.clinicEmail }),
        };

        const { data } = await axios(config);
        console.log(data);

        //let's send an email to clinic
        const payload = { email: self.clinicEmail, token: data.uniqueId };
        const configSendEmail = {
          method: "post",
          url: "/api/sendResetPasswordRequestEmail",
          headers: {
            "Content-Type": "application/json",
          },
          data: JSON.stringify(payload),
        };

        await axios(configSendEmail);

        alert(`Password reset link was sent to ${self.clinicEmail}!`);
        self.$router.push("/login");
        //console.log(result);
      }
    },
  },
};
</script>
