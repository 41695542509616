<template>
  <v-app>
    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="blue darken-3"
      dark
    >
      <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
        <span class="hidden-sm-and-down">Hound Ultrasound Portal</span>
      </v-toolbar-title>
    </v-app-bar>
    <v-main>
      <v-container>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="7" lg="7">
            <v-card class="elevation-8">
              <v-card-text>
                <v-form>
                  <h1 class="mb-6">Register clinic with HU portal</h1>
                  <v-text-field
                    v-model="clinicName"
                    label="Name"
                    required
                    hint="minimum 3 characters"
                  >
                    <template #label>
                      Name
                      <span class="required"><strong>(required)</strong></span>
                    </template>
                  </v-text-field>
                  <v-text-field v-model="clinicEmail" label="Email" required>
                    <template #label>
                      Email
                      <span class="required"><strong>(required)</strong></span>
                    </template>
                  </v-text-field>
                  <v-text-field
                    v-model="clinicEmailBilling"
                    label="Billing email"
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="clinicPhone"
                    label="Phone"
                    required
                    hint="like 999-999-9999"
                  >
                    <template #label>
                      Phone
                      <span class="required"><strong>(required)</strong></span>
                    </template>
                  </v-text-field>
                  <v-text-field
                    v-model="clinicFax"
                    label="Fax"
                    required
                  ></v-text-field>
                  <h2 class="mt-3 mb-1">Address</h2>
                  <v-text-field
                    v-model="address1"
                    label="Address Line 1"
                    required
                  >
                    <template #label>
                      Address Line 1
                      <span class="required"><strong>(required)</strong></span>
                    </template>
                  </v-text-field>
                  <v-text-field
                    v-model="address2"
                    label="Address Line 2"
                    required
                  ></v-text-field>
                  <v-text-field v-model="addressCity" label="City" required>
                    <template #label>
                      City
                      <span class="required"><strong>(required)</strong></span>
                    </template>
                  </v-text-field>
                  <v-text-field
                    v-model="addressProvince"
                    label="Province"
                    required
                    disabled
                  ></v-text-field>
                  <v-text-field
                    v-model="addressPostalCode"
                    label="Postal code"
                    required
                    hint="like M2R 2C2"
                  >
                    <template #label>
                      Postal code
                      <span class="required"><strong>(required)</strong></span>
                    </template>
                  </v-text-field>
                  <h2 class="mt-3 mb-1">Director / Head veterinarian</h2>
                  <v-text-field
                    v-model="clinicDirectorFN"
                    label="First name"
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="clinicDirectorLN"
                    label="Last name"
                    required
                  ></v-text-field>
                </v-form>
                <v-card-actions>
                  <v-btn
                    text
                    style="margin-left: 20px"
                    color="primary"
                    v-on:click="backToLogin"
                    >Back to login</v-btn
                  >
                  <v-spacer></v-spacer>
                  <v-btn
                    style="margin-left: 20px"
                    color="primary"
                    v-on:click="register"
                    :disabled="$v.$invalid"
                    >Register</v-btn
                  >
                </v-card-actions>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline"> Congratulations! </v-card-title>
        <v-card-text
          >You have succesfully registered for HU portal. After administrator
          approves your registration, login information will be emailed to you
          at {{ this.clinicEmail }}</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="backToLogin()"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import axios from "axios";
import { required, email, minLength } from "vuelidate/lib/validators";

export default {
  name: "Register",
  title: "HU Portal - Register",

  data() {
    return {
      clinicName: "",
      clinicEmail: "",
      clinicEmailBilling: "",
      clinicPhone: "",
      clinicFax: "",
      clinicDirectorFN: "",
      clinicDirectorLN: "",
      address1: "",
      address2: "",
      addressCity: "",
      addressProvince: "Ontario",
      addressPostalCode: "",
      error: "",
      dialog: false,
    };
  },

  validations: {
    clinicName: {
      required,
      minLength: minLength(3),
    },
    clinicEmail: {
      email,
      required,
    },
    clinicEmailBilling: {
      email,
    },
    clinicPhone: {
      required,
      minLength: minLength(12),
    },
    address1: {
      required,
    },
    addressCity: {
      required,
    },
    addressProvince: {
      required,
    },
    addressPostalCode: {
      required,
      isValidPostalCode: function (value) {
        var regex = new RegExp(
          /^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]( )?\d[ABCEGHJKLMNPRSTVWXYZ]\d$/i
        );
        //console.log(regex.test(value));
        return regex.test(value);
      },
    },
  },
  methods: {
    async register() {
      console.log(process.env.VUE_APP_RECAPTCHA_SITE_KEY);
      await this.$recaptchaLoaded();
      //pass token to server for verification in payload
      const recaptchaToken = await this.$recaptcha("registration");
      //console.log(recaptchaToken);

      const self = this;

      const payload = {
        recaptchaToken: recaptchaToken,
        name: self.clinicName,
        email: self.clinicEmail,
        email_billing: self.clinicEmailBilling,
        phone: self.clinicPhone,
        fax: self.clinicFax,
        director: {
          firstName: self.clinicDirectorFN,
          lastName: self.clinicDirectorLN,
        },

        approved: false,
        address: {
          address1: self.address1,
          address2: self.address2,
          city: self.addressCity,
          province: { id: 7, name: "Ontario" }, //Ontario, for now it's hardcoded
          postalCode: self.addressPostalCode,
        },
      };

      let config = {
        method: "put",
        url: "/api/self_signup",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(payload),
      };

      try {
        const response = await axios(config);
        const data = response.data;
        console.log(data);

        //show confirmation
        self.dialog = true;
      } catch (err) {
        console.log(err);
        self.error = JSON.stringify(err, null, 2);
      }
    },
    backToLogin() {
      //navigate to Login view
      this.$router.push({
        name: "login",
      });
    },
  },
};
</script>
