class cookieWrapper {
  constructor() {}

  static setToken(instance, token) {
    //save token to cookie
    if (process.env.NODE_ENV === "development")
      instance.$cookies.set("_t_", token, "", "/", "", false, "strict");
    else {
      //TODO: for now, we don't have SSL. But when we do change to TRUE
      instance.$cookies.set("_t_", token, "", "/", "", false, "strict");
    }
  }
  static getToken(instance) {
    return instance.$cookies.get("_t_");
  }

  static removeToken(instance) {
    instance.$cookies.remove("_t_");
  }
}

export default cookieWrapper;
