<template>
  <div>
    <h1>User Management</h1>
    <v-container>
      <v-progress-linear
        class="mt-2"
        :active="loading"
        :indeterminate="loading"
        absolute
        top
        color="indigo accent-4"
      ></v-progress-linear>
      <v-row class="mb-3"><h2>Active logins</h2></v-row>

      <v-row>
        <v-card v-for="c in active_users" :key="c.id" class="mx-3 elevation-8">
          <v-card-title>{{ c.name }}</v-card-title>
          <v-card-subtitle class="indigo--text">{{ c.email }}</v-card-subtitle>
          <v-card-text v-if="c.approval_date"
            >Approval date: {{ date_formatted(c.approval_date) }}</v-card-text
          >
          <v-card-actions>
            <v-btn small text color="deep-orange darken-4">Block</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              @click="$router.push(`/home/admin-manage-clinic/${c.id}`)"
              small
              text
              color="indigo darken-4"
              >Change Password</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-row>
      <v-row class="mt-5"
        ><v-sheet
          >You have
          <v-avatar color="green darken-4" size="45">
            <span class="white--text">{{ active_users.length }}</span>
          </v-avatar>
          active users</v-sheet
        ></v-row
      >
    </v-container>
    <v-container>
      <v-row class="mb-3"><h2>Disabled logins</h2></v-row>
      <v-row>
        <v-card
          v-for="c in inactive_users"
          :key="c.id"
          class="mx-3 elevation-8"
          style="border: 1px red solid"
        >
          <v-card-title> {{ c.name }}</v-card-title>
          <v-card-subtitle class="indigo--text">{{ c.email }}</v-card-subtitle>
          <v-card-text v-if="c.approval_date"
            >Approval date: {{ date_formatted(c.approval_date) }}</v-card-text
          >
          <v-card-actions>
            <v-btn small text color="deep-orange darken-4">Un-Block</v-btn>
          </v-card-actions>
        </v-card>
      </v-row>
      <v-row class="mt-5"
        ><v-sheet
          >You have
          <v-avatar color="red darken-2" size="45">
            <span class="white--text">{{ inactive_users.length }}</span>
          </v-avatar>
          inactive users</v-sheet
        ></v-row
      >
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import cookieWrapper from "../../auth/cookieWrapper";
import dateTimeFormatter from "../../util/dateTimeFormatter";

export default {
  name: "AdminManageUsers",
  title: "HU Portal - Users",

  data() {
    return {
      users: [],
      loading: true,
    };
  },

  async mounted() {
    let self = this;

    try {
      const token = cookieWrapper.getToken(self);

      let config = {
        method: "get",
        url: `/api/user/all?secret_token=${token}`,
        headers: {
          "Content-Type": "application/json",
        },
      };

      let result = await axios(config);
      self.users = result.data;

      console.log(result.data);
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  },

  computed: {
    active_users() {
      return this.users.filter((i) => {
        return i.isApproved;
      });
    },
    inactive_users() {
      return this.users.filter((i) => {
        return !i.isApproved;
      });
    },
  },

  methods: {
    date_formatted(d) {
      return dateTimeFormatter.formatDateTime(d);
    },
  },
};
</script>
