<template>
  <v-app>
    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="blue darken-3"
      dark
    >
      <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
        <span class="hidden-sm-and-down">Hound Ultrasound Portal</span>
      </v-toolbar-title>
    </v-app-bar>
    <v-main>
      <v-container>
        <v-alert color="red"
          ><span style="color: white"
            >Oops.. Something went wrong. System has logged an exception.</span
          ></v-alert
        >
        <div><a v-on:click="$router.push(backUrl)">Back home</a></div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "ApplicationError",
  title: "Application error",

  computed: {
    backUrl() {
      if (this.$store.state.isUserAdmin) {
        return "/home/admin-landing";
      } else {
        return "/home/patients";
      }
    },
  },
};
</script>