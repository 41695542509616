var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('h1',[_vm._v("Patient Visits")])]),_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"md":"12","sm":"5","lg":"12","col":"12"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"ml-2 mt-2"},[_vm._v("10 latest forms")])]),_c('v-data-table',{attrs:{"dense":"","hide-default-footer":"","itemsPerPage":_vm.itemsPerPage,"headers":_vm.headers,"items":_vm.forms,"loading":_vm.loading,"loading-text":"Loading... Please wait","sort-by":['dateCreated'],"sort-desc":[true]},scopedSlots:_vm._u([{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"title":"Review patient visit","color":"blue darken-2"},on:{"click":function($event){return _vm.$router.push(
                  ("/home/patient-form/" + (item.patientId) + "/" + (item.id))
                )}}},[_vm._v(" mdi-clipboard-edit ")])]}},{key:"item.dateCreated",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatdDate(item.dateCreated)))]}},{key:"item.edit1",fn:function(ref){
                var item = ref.item;
return [_c('v-icon',{attrs:{"title":"Add new patient visit for this patient","color":"blue darken-2"},on:{"click":function($event){return _vm.$router.push(("/home/create-new-form/" + (item.patientId)))}}},[_vm._v(" mdi-clipboard ")])]}}],null,true)})],1)],1)],1),_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"md":"12","sm":"5","lg":"12","col":"12"}},[_c('SearchPatient')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }