<template>
  <v-container>
    <v-row align="center" justify="center">
      <h1>Patient Visits</h1>
    </v-row>
    <v-row justify="center" align="center">
      <v-col md="12" sm="5" lg="12" col="12">
        <v-card>
          <v-card-title
            ><span class="ml-2 mt-2">10 latest forms</span></v-card-title
          >
          <v-data-table
            dense
            hide-default-footer
            :itemsPerPage="itemsPerPage"
            :headers="headers"
            :items="forms"
            :loading="loading"
            loading-text="Loading... Please wait"
            :sort-by="['dateCreated']"
            :sort-desc="[true]"
          >
            <template v-slot:[`item.edit`]="{ item }">
              <v-icon
                title="Review patient visit"
                color="blue darken-2"
                @click="
                  $router.push(
                    `/home/patient-form/${item.patientId}/${item.id}`
                  )
                "
              >
                mdi-clipboard-edit
              </v-icon></template
            >
            <template v-slot:[`item.dateCreated`]="{ item }">
              {{ formatdDate(item.dateCreated) }}</template
            >

            <template v-slot:[`item.edit1`]="{ item }">
              <v-icon
                title="Add new patient visit for this patient"
                color="blue darken-2"
                @click="$router.push(`/home/create-new-form/${item.patientId}`)"
              >
                mdi-clipboard
              </v-icon></template
            >
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center" align="center">
      <v-col md="12" sm="5" lg="12" col="12">
        <SearchPatient></SearchPatient>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import cookieWrapper from "../auth/cookieWrapper";
import dateTimeFormatter from "../util/dateTimeFormatter";
import SearchPatient from "./sub/SearchPatient";

export default {
  name: "PatientForms",
  title: "HU Portal - Manage Patient Visits",
  components: {
    SearchPatient,
  },
  data() {
    return {
      itemsPerPage: 10,
      loading: true,
      forms: [],
      headers: [
        { text: "Patient", value: "patient", width: "15%" },
        { text: "Date created", value: "dateCreated" },
        { text: "Reason", value: "reason", width: "21%" },
        { text: "Addendums", value: "addendumCount" },
        { text: "Documents", value: "documentCount" },
        { text: "Add", value: "edit1", filterable: false },
        { text: "Review", value: "edit", filterable: false },
      ],
    };
  },
  async mounted() {
    let self = this;

    try {
      const token = cookieWrapper.getToken(self);

      let config = {
        method: "get",
        url: `/api/patient-form/all?clinicId=${self.$store.state.loggedInClinicId}&secret_token=${token}`,
        headers: {
          "Content-Type": "application/json",
        },
      };

      let result = await axios(config);
      self.forms = result.data;

      console.log(result.data);
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  },

  methods: {
    formatdDate(d) {
      return dateTimeFormatter.formatWithMask(d, "YYYY-MM-DD hh:mm A");
    },
  },
};
</script>
