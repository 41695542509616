<template>
  <v-app id="inspire">
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="5">
            <v-card class="elevation-12">
              <v-toolbar color="primary" dark flat>
                <v-toolbar-title
                  >Login to Hound Ultrasound Portal</v-toolbar-title
                >
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      :href="main_url"
                      icon
                      large
                      target="_blank"
                      v-on="on"
                    >
                      <v-icon>mdi-web</v-icon>
                    </v-btn>
                  </template>
                  <span>Main Site</span>
                </v-tooltip>
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-text-field
                    label="Login"
                    name="login"
                    prepend-icon="mdi-account"
                    type="text"
                    v-model="user"
                  ></v-text-field>
                  <v-text-field
                    :append-icon="showpwd ? 'mdi-eye' : 'mdi-eye-off'"
                    id="password"
                    label="Password"
                    name="password"
                    prepend-icon="mdi-lock"
                    :type="showpwd ? 'text' : 'password'"
                    v-model="password"
                    v-on:keyup="onKeyUp"
                    @click:append="showpwd = !showpwd"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <!-- <v-btn
                  text
                  style="margin-left: 20px"
                  color="primary"
                  v-on:click="register"
                  >Register</v-btn
                > -->

                <v-btn
                  text
                  style="margin-left: 20px"
                  color="primary"
                  v-on:click="password_reset"
                  >Reset password</v-btn
                >
                <v-spacer></v-spacer>
                <v-btn
                  style="margin-right: 20px"
                  :disabled="$v.$invalid"
                  v-on:click="login"
                  color="primary"
                  >Login</v-btn
                >
              </v-card-actions>
              <v-card-text>
                <v-alert v-if="error" type="error">
                  {{ error }}
                </v-alert>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { required, email, minLength } from "vuelidate/lib/validators";
import axios from "axios";
import qs from "qs";
import cookieWrapper from "../auth/cookieWrapper";

export default {
  name: "Login",
  title: "HU Portal - Login",
  data() {
    return {
      main_url: process.env.VUE_APP_MAIN_SITE_URL,
      user: "",
      password: "",
      error: "",
      showpwd: false,
    };
  },
  validations: {
    user: {
      email,
      required,
    },
    password: {
      required,
      minLength: minLength(6),
    },
  },

  mounted() {
    //TODO: clear vuex state
  },

  methods: {
    async login() {
      let self = this;

      let payload = qs.stringify({
        email: this.user,
        password: this.password,
      });

      let config = {
        method: "post",
        url: "/api/login",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: payload,
      };

      try {
        const response = await axios(config);
        const data = response.data;

        config = {
          method: "get",
          url: `/api/user/profile?secret_token=${data.token}`,
          headers: {},
        };

        const r = await axios(config);
        console.log(r);

        //save Vuex store
        self.$store.commit("setLoggedInUser", r.data.user.email);
        self.$store.commit("setLoggedInUserId", r.data.user._id);

        //save token to cookie
        cookieWrapper.setToken(self, data.token);

        if (r.data.user.roles.length > 1) {
          const admin = r.data.user.roles.filter((i) => {
            return i === "admin";
          });

          const staff = r.data.user.roles.filter((i) => {
            return i === "staff";
          });

          self.$store.commit("setIsAdmin", admin.length > 0 ? true : false);
          self.$store.commit("setIsStaff", staff.length > 0 ? true : false);

          //navigate to Home- ADMIN view
          self.$router.push({
            name: "admin-landing",
          });
        } else {
          self.$store.commit("setIsAdmin", false);
          self.$store.commit("setIsStaff", false);
          self.$store.commit("setLoggedInClinicId", r.data.user.clinicId);
          self.$store.commit("setLoggedInClinic", r.data.clinic);

          if (self.$router.history.current.query.p != undefined) {
            self.$router.push({
              name: "fee_schedule", //todo: do not hardcode
            });
          } else {
            //navigate to Home view
            self.$router.push({
              name: "patients",
            });
          }
        }
      } catch (err) {
        console.log(err.response.data.error.message);
        if (err.response && err.response.data) {
          if (err.response.data.error.message == "Clinic not approved") {
            self.error =
              "We were unable to log you in. Please contacy HU Portal admin for futher assistance";
          } else if (err.response.data.error.message == "User not found") {
            self.error = "Invalid email or password";
          } else if (err.response.data.error.message == "Wrong Password") {
            self.error = "Invalid email or password";
          } else {
            self.error = "Server error";
          }
        } else {
          self.error = "Server error";
        }
      }
    },

    // register() {
    //   this.$router.push({
    //     name: "register",
    //   });
    // },

    password_reset() {
      this.$router.push({
        name: "password-reset",
      });
    },

    onKeyUp(e) {
      //Enter key was pressed
      if (e.keyCode === 13) {
        if (!this.$v.$invalid) {
          this.login();
        }
      }
    },
  },
};
</script>
