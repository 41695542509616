<template>
  <div>search result admin</div>
</template>


<script>
export default {
  name: "SearchResultAdmin",
  title: "Search results",
};
</script>