<template>
  <v-container>
    <v-row align="center" justify="center">
      <h1>Registered Patients</h1>
    </v-row>
    <v-row align="center" justify="center">
      <v-col md="12" sm="5" lg="12" col="12">
        <v-sheet class="mt-2 mb-2"
          ><v-btn
            large
            color="primary"
            @click="$router.push(`/home/create-patient`)"
          >
            Add new patient
          </v-btn></v-sheet
        ></v-col
      >
    </v-row>

    <v-row justify="center" align="center">
      <v-col md="12" sm="5" lg="12" col="12">
        <v-card>
          <v-card-title>
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              label="Search / Edit existing patients"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="patients"
            :search="search"
            :items-per-page="itemsPerPage"
            :loading="loading"
            loading-text="Loading... Please wait"
            :sort-by="sortBy"
            :expanded.sync="itemExpanded"
            show-expand
            single-expand
            @item-expanded="onRowExpand"
            item-key="_id"
          >
            <template v-slot:[`item.dateCreated`]="{ item }">
              {{ formatdDate(item.dateCreated) }}</template
            >
            <template v-slot:[`item.edit`]="{ item }">
              <v-icon
                title="Edit patient"
                color="blue darken-2"
                @click="$router.push(`/home/manage-patient/${item._id}`)"
              >
                mdi-account-edit
              </v-icon></template
            >
            <template v-slot:[`item.manage-form`]="{ item }">
              <v-icon
                title="Add new visit"
                color="blue darken-2"
                @click="$router.push(`/home/create-new-form/${item._id}`)"
              >
                mdi-clipboard
              </v-icon></template
            >

            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" style="padding: 15px">
                <div v-if="!item.forms">Loading..</div>
                <table
                  v-if="item && item.forms && item.forms.length > 0"
                  style="width: 70%"
                >
                  <caption style="text-align: start; margin-bottom: 7px">
                    Patient visit(s)
                  </caption>
                  <tr style="text-align: left">
                    <th>Visit date</th>
                    <th>Visit history</th>
                    <td></td>
                  </tr>
                  <tr v-for="i in item.forms" :key="i._id">
                    <td>{{ formatdDate(i.dateCreated) }}</td>
                    <td>{{ i.reason }}</td>
                    <td>
                      <v-icon
                        title="Review patient visit"
                        color="blue darken-2"
                        @click="
                          $router.push(
                            `/home/patient-form/${item._id}/${i._id}`
                          )
                        "
                      >
                        mdi-clipboard-edit
                      </v-icon>
                    </td>
                  </tr>
                </table>

                <span v-if="item && item.forms && item.forms.length == 0"
                  >no visits yet</span
                >
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import cookieWrapper from "../auth/cookieWrapper";
import dateTimeFormatter from "../util/dateTimeFormatter";

export default {
  name: "Patients",
  title: "HU Portal - Manage Patients",
  data() {
    return {
      itemsPerPage: 10,
      model: null,
      loading: true,

      search: "",
      headers: [
        {
          text: "Name",
          value: "name",
        },
        { text: "Date created", value: "dateCreated" },
        { text: "Species", value: "species.name" },
        { text: "Gender", value: "gender.name" },
        { text: "Visits", value: "visit_count" },
        { text: "", value: "edit", filterable: false },
        { text: "", value: "manage-form", filterable: false },
      ],

      patients: [],
      itemExpanded: [],
      sortBy: ["name"],
    };
  },
  async mounted() {
    let self = this;

    try {
      const token = cookieWrapper.getToken(self);

      let config = {
        method: "get",
        url: `/api/patient/all?clinicId=${self.$store.state.loggedInClinicId}&secret_token=${token}`,
        headers: {
          "Content-Type": "application/json",
        },
      };

      const result = await axios(config);

      //add forms fields. it will be filled on row expand in onRowExpand
      self.patients = result.data.map((item) => {
        return {
          forms: {},
          ...item,
        };
      });

      //console.log(result.data);
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    edit(id) {
      console.log(id);
    },

    formatdDate(d) {
      //return dateTimeFormatter.formatDateTime(d);
      return dateTimeFormatter.formatWithMask(d, "YYYY-MM-DD hh:mm A");
    },

    async onRowExpand({ item, value }) {
      let self = this;

      if (value != true) {
        return;
      }

      const token = cookieWrapper.getToken(self);

      let config = {
        method: "get",
        url: `/api/patient-form/formsByPatient/${item._id}?secret_token=${token}`,
        headers: {
          "Content-Type": "application/json",
        },
      };

      const patient_forms = await axios(config);

      item.forms = patient_forms.data;
    },
  },
};
</script>
