var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-progress-linear',{staticClass:"mt-2",attrs:{"active":_vm.loading,"indeterminate":_vm.loading,"absolute":"","top":"","color":"indigo accent-4"}}),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('h1',[_vm._v("Clinics")])]),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$store.state.isUserStaff),expression:"!$store.state.isUserStaff"}],attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"md":"12","sm":"5","lg":"12","col":"12"}},[_c('v-sheet',{staticClass:"mt-2 mb-2"},[_c('v-btn',{attrs:{"large":"","color":"primary"},on:{"click":function($event){return _vm.$router.push("/home/admin-register-clinic")}}},[_vm._v(" Register new clinic ")])],1)],1)],1),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"md":"12","sm":"5","lg":"12","col":"12"}},[_c('v-row',{staticClass:"mb-3",attrs:{"align":"center","justify":"center"}},[_c('h2',[_vm._v(" Approved clinics "),_c('v-avatar',{attrs:{"color":"green darken-4","size":"45"}},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.approved_clinics.length))])])],1)]),_c('v-data-table',{attrs:{"show-expand":"","loading":_vm.loading,"loading-text":"Loading... Please wait","item-key":"clinic._id","single-expand":false,"headers":_vm.headers,"items":_vm.approved_clinics,"items-per-page":_vm.itemsPerPage,"footer-props":{
          'items-per-page-options': [10, 50, 100, -1],
        },"sort-by":['clinic.dateCreated'],"sort-desc":[true],"item-class":_vm.calculateRowCSSClass,"search":_vm.search},on:{"item-expanded":_vm.onRowExpand},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.clinic.dateCreated",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatdDate(item.clinic.dateCreated)))]}},{key:"item.clinic.dateApproved",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatdDate(item.clinic.dateApproved)))]}},{key:"item.edit",fn:function(ref){
        var item = ref.item;
return [_c('v-icon',{attrs:{"title":"Edit clinic","color":"blue darken-2"},on:{"click":function($event){return _vm.$router.push(("/home/admin-manage-clinic/" + (item.clinic._id)))}}},[_vm._v(" mdi-pencil ")])]}},{key:"expanded-item",fn:function(ref){
        var headers = ref.headers;
        var item = ref.item;
return [_c('td',{staticStyle:{"padding":"15px"},attrs:{"colspan":headers.length}},[(item && item.patients && item.patients.length > 0)?_c('table',{staticStyle:{"width":"90%","padding-left":"10px"},attrs:{"role":"presentation"}},[_c('caption',[_vm._v(" Clinic's patients ")]),_c('tr',{staticStyle:{"text-align":"left"}},[_c('th',[_vm._v("Name")]),_c('th',[_vm._v("Breed")]),_c('th',[_vm._v("Species")]),_c('th',[_vm._v("Gender")]),_c('th',[_vm._v("Visits")]),_c('td')]),_vm._l((item.patients),function(i){return _c('tr',{key:i._id},[_c('td',[_c('a',{attrs:{"href":("/home/manage-patient/" + (i._id))}},[_vm._v(_vm._s(i.name)+" "+_vm._s(i.lastName)+" ")])]),_c('td',[_vm._v(_vm._s(i.breed))]),_c('td',[_vm._v(_vm._s(i.species.name))]),_c('td',[_vm._v(_vm._s(i.gender.name))]),_c('td',[_vm._v(_vm._s(i.visit_count))]),_c('td',[_c('v-icon',{attrs:{"title":"View visits","color":"blue darken-2"},on:{"click":function($event){return _vm.$router.push(("/home/admin-patient-visits/" + (i._id)))}}},[_vm._v(" mdi-clipboard-account ")])],1)])})],2):_vm._e(),(item && item.patients && item.patients.length == 0)?_c('span',[_vm._v("no patients yet")]):_vm._e()])]}}],null,true)})],1)],1),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"md":"12","sm":"5","lg":"12","col":"12"}},[_c('v-row',{staticClass:"mb-3",attrs:{"align":"center","justify":"center"}},[_c('h2',[_vm._v(" Not approved clinics "),_c('v-avatar',{attrs:{"color":"red darken-2","size":"45"}},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.not_approved_clinics.length))])])],1)]),_c('v-data-table',{attrs:{"show-expand":"","item-key":"clinic._id","single-expand":false,"headers":_vm.headers,"items":_vm.not_approved_clinics,"items-per-page":_vm.itemsPerPage,"sort-by":['clinic.dateCreated'],"sort-desc":[true]},scopedSlots:_vm._u([{key:"item.clinic.dateCreated",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatdDate(item.clinic.dateCreated)))]}},{key:"item.clinic.dateApproved",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatdDate(item.clinic.dateApproved)))]}},{key:"item.edit",fn:function(ref){
        var item = ref.item;
return [_c('v-icon',{attrs:{"title":"Edit clinic","color":"blue darken-2"},on:{"click":function($event){return _vm.$router.push(("/home/admin-manage-clinic/" + (item.clinic._id)))}}},[_vm._v(" mdi-pencil ")])]}},{key:"expanded-item",fn:function(ref){
        var headers = ref.headers;
        var item = ref.item;
return [_c('td',{staticStyle:{"padding":"15px"},attrs:{"colspan":headers.length}},[(item && item.patients && item.patients.length > 0)?_c('div',[_vm._v(" Clinic's patients ")]):_vm._e(),(item && item.patients && item.patients.length > 0)?_c('table',{attrs:{"width":"100%"}},[_c('caption',[_vm._v(" Clinic's patients ")]),_c('tr',{staticStyle:{"text-align":"left"}},[_c('th',[_vm._v("Name")]),_c('th',[_vm._v("Breed")]),_c('th',[_vm._v("Species")]),_c('th',[_vm._v("Gender")])]),_vm._l((item.patients),function(i){return _c('tr',{key:i._id},[_c('td',[_c('a',{attrs:{"href":("/home/manage-patient/" + (i._id))}},[_vm._v(_vm._s(i.name)+" "+_vm._s(i.lastName)+" ")])]),_c('td',[_vm._v(_vm._s(i.breed))]),_c('td',[_vm._v(_vm._s(i.species.name))]),_c('td',[_vm._v(_vm._s(i.gender.name))])])})],2):_vm._e(),(item && item.patients && item.patients.length == 0)?_c('span',[_vm._v("no patients yet")]):_vm._e()])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }